import { isRunningTests } from '@glow/common'
import i18next from 'i18next'
import { ENTITIES_RECEIVED, TURN_OFF_WEBSOCKETS } from '../actions/actionTypes'
import { addNotification, dismissNotification } from '../actions/creators/helpersNotifications'
import { userRequiresPwdLogin } from '../utils/loginUtils'
import { changeWSContext, startWS, stopWS } from '../ws/wshandler'

export type Action = { type: string; redirect: boolean }

export const wsMiddleware = (store: any) => (next: (action: unknown) => void) => (action: unknown) => {
  const oldUser = store.getState().get('user')
  const oldRole = oldUser.get('role')
  const oldContext = store.getState().get('context')

  const res = next(action)

  const newUser = store.getState().get('user')
  const newContext = store.getState().get('context')

  const actionObject = typeof action === 'object' ? (action as Action) : undefined

  if (actionObject && TURN_OFF_WEBSOCKETS === actionObject.type) {
    stopWS()
  }

  const changedContext = !oldContext.equals(newContext)
  if (!changedContext && oldUser && oldUser.equals(newUser)) {
    return res
  }
  if (changedContext) {
    changeWSContext(newContext)
  }

  const newUserRole = newUser.get('role')

  const requiresPwd = newUser.get('requiresPwd')
  const requiresEmail = newUser.get('requiresEmail')

  const fullLogin = !requiresEmail && !requiresPwd && !userRequiresPwdLogin(newUser)

  if (oldRole && !newUserRole) {
    stopWS()
  } else if (newUserRole && fullLogin && !isRunningTests()) {
    startWS(store, wsHandlers(store))
  }
  return res
}

const wsHandlers = (store: any) => ({
  messageHandler: (data: unknown) => {
    store.dispatch({
      meta: { raf: true }, //Make ws msg processing run batched in animation frames to increase performance, see rafScheduler.ts
      data: data,
      consignmentStatesAccepted: store.getState().getIn(['context', 'consignmentStates']),
      type: ENTITIES_RECEIVED
    })
  },
  errorHandler: (error: string) => {
    console.debug('errorHandler', error)
    store.dispatch(
      addNotification({
        id: '9999',
        title: i18next.t('notifications.error'),
        message: i18next.t(error),
        type: 'error',
        button: 'reload',
        timeout: undefined
      })
    )
  },
  clearErrorHandler: () => {
    store.dispatch(dismissNotification(9999))
  }
})
