import { isFeatureTogglesOn } from '@glow/common'
import { ConsignmentState } from '@glow/entity-types'
import { fromJS, Map as IMap } from 'immutable'
import { DateTime } from 'luxon'
import {
  CLEAR_ALL_ENTITIES,
  CLEAR_ENTITIES,
  CLEAR_ENTITY_COMMAND_RESULT,
  CLEAR_OPTIMIZE_RESULTS,
  CLEAR_PRICE_CORRECTIONS,
  CREATE_ORDER_OPTIMUS_SUCCESS,
  ENTITIES_RECEIVED,
  GET_ORDER_REQUEST
} from '../actions/actionTypes'
import { MainAccessEntity } from '../domain/mainAccessType'
import { convert, MeasurementUnit } from '../domain/measurementUnits'

export const entityInitialState = IMap({
  addresses: IMap(),
  airExpressOrders: IMap(),
  airexpressRecurringSchedules: IMap(),
  airexpressRecurringTemplates: IMap(),
  airlineTerminals: IMap(),
  airlines: IMap(),
  airports: IMap(),
  allDepartments: IMap(),
  areas: IMap(),
  consignmentEventActions: IMap(),
  consignmentEvents: IMap(),
  consignments: IMap(),
  courierLocations: IMap(),
  units: IMap(),
  customMeasurements: IMap(),
  dangerousGoods: IMap(),
  deletedConsignments: IMap(),
  departments: IMap(),
  importResults: IMap(),
  importStatuses: IMap(),
  invoiceItems: IMap(),
  labelUrls: IMap(),
  locallyAddedFlightSchedules: IMap(),
  manifests: IMap(),
  optimizeJobResults: IMap(),
  orderCorrections: IMap(),
  orderInvoicingExecutions: IMap(),
  orders: IMap(),
  orderNotes: IMap(),
  recurringOrderExecutions: IMap(),
  recurringOrders: IMap(),
  routePointSuggestions: IMap(),
  routePoints: IMap(),
  slotProgress: IMap(),
  slots: IMap(),
  templateSlots: IMap(),
  templates: IMap(),
  users: IMap(),
  whiteList: IMap(),
  articlePriceOverrides: IMap()
})

const valueOrEmpty = (name: string, data: unknown, dataMap?: { mapEntries: any }) => {
  if (dataMap && dataMap['mapEntries']) {
    return dataMap
  } else {
    console.error(`Got an empty ${name} for entity, or not of correct type, returning empty map. Data looks like`, data)
    return IMap() as any
  }
}

export const replaceEntity = (hasKey: boolean, existingEntity: any, newEntity: IMap<unknown, any> | null) => {
  if (!existingEntity && !hasKey) return true
  if (!existingEntity && hasKey) return false

  const existingTs = existingEntity.get('updatedAtEpoch')
  const newDataTs = newEntity && newEntity.get('updatedAtEpoch')
  if (!existingTs || !newDataTs) return true
  return newDataTs >= existingTs
}

const handleEntities = (
  state: IMap<unknown, unknown>,
  data: unknown,
  consignmentStatesAccepted?: ConsignmentState[]
) => {
  const cache = new Map()
  return state.withMutations((mState) =>
    // @ts-ignore
    valueOrEmpty('root-data', data, fromJS(data)).mapEntries(([dataType, dataMap]) =>
      // @ts-ignore
      valueOrEmpty('data-map', data, dataMap).mapEntries(([entityIdString, actualData]) => {
        const entityId = parseInt(entityIdString, 10)
        const keyPath = [dataType, entityId]
        if (shouldDeleteConsignmentFromState(dataType, actualData, consignmentStatesAccepted)) {
          mState.removeIn(keyPath)
        } else if (actualData) {
          const existing = mState.getIn(keyPath)
          const hasKey = existing != null || mState.hasIn(keyPath)
          if (replaceEntity(hasKey, existing, actualData)) {
            mState.setIn(keyPath, enrichEntities(dataType, actualData, cache))
          }
        } else {
          mState.removeIn(keyPath)
        }
      })
    )
  )
}

export const shouldDeleteConsignmentFromState = (
  dataType: string,
  actualData?: IMap<unknown, any>,
  consignmentStatesAccepted?: ConsignmentState[]
): boolean => {
  if (
    !actualData ||
    !consignmentStatesAccepted ||
    (consignmentStatesAccepted && consignmentStatesAccepted.length === 0) ||
    dataType !== 'consignments' ||
    isFeatureTogglesOn(['old-ws'])
  ) {
    return false
  } else if (
    dataType === 'consignments' &&
    actualData.has('state') &&
    !consignmentStatesAccepted.includes(actualData.get('state'))
  ) {
    return true
  }
  return false
}

const enrichEntities = (dataType: string, actualData: IMap<unknown, any>, cache: Map<any, any>) => {
  switch (dataType) {
    case 'consignments':
    case 'deletedConsignments': {
      const deliveryEarliest = fromCacheOr(
        cache,
        actualData.get('deliveryTimeEarliest'),
        (value) => value && DateTime.fromISO(value as string)
      )
      const deliveryLatest = fromCacheOr(
        cache,
        actualData.get('deliveryTimeLatest'),
        (value) => value && DateTime.fromISO(value as string)
      )
      const pickupEarliest = fromCacheOr(
        cache,
        actualData.get('pickupTimeEarliest'),
        (value) => value && DateTime.fromISO(value as string)
      )
      const pickupLatest = fromCacheOr(
        cache,
        actualData.get('pickupTimeLatest'),
        (value) => value && DateTime.fromISO(value as string)
      )
      const weight = convert(actualData.get('weightUnit'), MeasurementUnit.KGM, actualData.get('weightValue'))
      const volume = convert(actualData.get('volumeUnit'), MeasurementUnit.DMQ, actualData.get('volumeValue'))
      const width = convert(actualData.get('widthUnit'), MeasurementUnit.CMT, actualData.get('widthValue'))
      const height = convert(actualData.get('heightUnit'), MeasurementUnit.CMT, actualData.get('heightValue'))
      const length = convert(actualData.get('lengthUnit'), MeasurementUnit.CMT, actualData.get('lengthValue'))
      return actualData.withMutations((mutator) => {
        weight && mutator.set('weightValue', weight).set('weightUnit', MeasurementUnit.KGM)
        volume && mutator.set('volumeValue', volume).set('volumeUnit', MeasurementUnit.DMQ)
        width && mutator.set('widthValue', width).set('widthUnit', MeasurementUnit.CMT)
        height && mutator.set('heightValue', height).set('heightUnit', MeasurementUnit.CMT)
        length && mutator.set('lengthValue', length).set('lengthUnit', MeasurementUnit.CMT)
        return mutator
          .set('deliveryTimeEarliestCached', deliveryEarliest)
          .set('deliveryTimeLatestCached', deliveryLatest)
          .set('pickupTimeEarliestCached', pickupEarliest)
          .set('pickupTimeLatestCached', pickupLatest)
      })
    }
    case 'routePoints': {
      const arrival = fromCacheOr(cache, actualData.get('arrival'), (value) => DateTime.fromISO(value as string))
      return actualData.set('arrivalCached', arrival)
    }
    case 'routePointSuggestions': {
      const arrival = fromCacheOr(cache, actualData.get('arrival'), (value) => DateTime.fromISO(value as string))
      return actualData.set('arrivalCached', arrival)
    }
    default:
      return actualData
  }
}

export const fromCacheOr = (
  cache: Map<any, any>,
  value: string | number,
  orFn: (value: string | number) => DateTime | undefined | '' | number
) => {
  const fromCache = cache.get(value)
  if (fromCache) return fromCache
  else {
    const toCache = orFn(value)
    if (toCache) {
      cache.set(value, toCache)
    }
    return toCache
  }
}

interface EntityAction {
  type: string
  data?: unknown
  body?: object
  entityTypes?: MainAccessEntity[]
  commandResultType?: string
  articleCode?: string
  isEntity?: boolean
  consignmentStatesAccepted?: ConsignmentState[]
}

export const entities = (state = entityInitialState, action: EntityAction) => {
  if (action.type === ENTITIES_RECEIVED) {
    return handleEntities(state, action.data, action.consignmentStatesAccepted)
  } else if (
    action.type === 'SEARCH_ORDERS_SUCCESS' ||
    action.type === 'GET_CONSIGNMENTS_FOR_INVOICE_HANDLING_SUCCESS'
  ) {
    // @ts-ignore
    const { orderSearchs, ...data } = action.body // eslint-disable-line @typescript-eslint/no-unused-vars
    return handleEntities(state, data)
  } else if (action.isEntity) {
    return handleEntities(state, action.body)
  } else if (action.type === CLEAR_OPTIMIZE_RESULTS) {
    return state.set('optimizeJobResults', IMap()) //.set('routePointSuggestions', IMap())
  } else if (action.type === CLEAR_ENTITIES) {
    return state.withMutations((mState) => action.entityTypes?.forEach((entityType) => mState.set(entityType, IMap())))
  } else if (action.type === CLEAR_ALL_ENTITIES) {
    return entityInitialState
  } else if (action.type === CLEAR_ENTITY_COMMAND_RESULT) {
    // @ts-ignore
    const commandResults = (state.getIn(['commandResults', action.commandResultType]) || IMap()).map(
      (result: { type: string }) => (result.type === action.commandResultType ? IMap() : result)
    )
    return state.setIn(['commandResults'], commandResults)
  } else if (action.type === GET_ORDER_REQUEST || action.type === CREATE_ORDER_OPTIMUS_SUCCESS) {
    return state.set('dangerousGoods', IMap())
  } else if (action.type === CLEAR_PRICE_CORRECTIONS) {
    if (action.articleCode) {
      return state.withMutations((mState) => {
        return mState.set(
          'articlePriceOverrides',
          // @ts-ignore
          state.get('articlePriceOverrides')?.filter((apo) => apo.get('articleCode') !== action.articleCode) ?? IMap()
        )
      })
    } else {
      return state.set('articlePriceOverrides', IMap())
    }
  } else {
    return state
  }
}
