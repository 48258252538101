import { isBring, isPTVCloudEnabled } from '@glow/common'

export enum OptimizingDataType {
  Request = 'request',
  Response = 'response',
  Error = 'error'
}

export enum OptimizingType {
  PTV = 'PTV',
  PTV_CLOUD = 'PTV_CLOUD',
  GRAPHHOPPER = 'GRAPHHOPPER',
  NONE = 'NONE'
}

export const optimizingTypeFromString = (optimizingTypeString: string | null): OptimizingType | undefined => {
  let optimizingType = OptimizingType[optimizingTypeString as keyof typeof OptimizingType]

  if (optimizingType == OptimizingType.PTV_CLOUD && !isPTVCloudEnabled()) {
    if (isBring()) {
      return OptimizingType.PTV
    } else {
      return OptimizingType.GRAPHHOPPER
    }
  } else {
    return optimizingType
  }
}
