import i18next from 'i18next'
import { List, Map } from 'immutable'
import { DateTime } from 'luxon'
import React from 'react'
// @ts-expect-error
import { Control, Errors, Form } from 'react-redux-form/lib/immutable'
import styled from 'styled-components'
import { AcceptButton } from '../../primitives/Buttons'
import { SmallValidationError } from '../../primitives/ErrorMessages'
import { Input, LabelNew, LabelText } from '../../primitives/Forms'
import ImmutableComponent from '../../primitives/ImmutableComponent'
import Select from '../../primitives/Select'
import vars from '../../styles/variables'
import { ImmutableMapFactory } from '../../types/immutableTypes'
import { required } from '../../utils/inputValidation'
import { Day, RecurringOrderProps, RecurringOrderType } from './recurringOrderTypes'
import classNames from 'classnames'
import { FormDate, IconFa } from '@glow/ui-components'
import { userLanguage } from '@glow/common'
import { isBefore, parseISO } from 'date-fns'

interface Props {
  onSubmit: (form: RecurringOrderType) => any
  formValues: RecurringOrderType
  mergeFormValues: (model: string, newVals: Map<string, any>) => void
}

const CheckboxText = styled.span`
  margin-left: 0.4em;
`

const frequencies = ['WEEKLY', 'SECOND_WEEK', 'THIRD_WEEK', 'MONTHLY'] as const
type Frequency = (typeof frequencies)[number]

export default class RecurringOrderForm extends ImmutableComponent<Props> {
  constructor(props: Props) {
    super(props)
    this.state = { submitting: false }
  }

  static daysInitialState = Map([
    ['monday', false],
    ['tuesday', false],
    ['wednesday', false],
    ['thursday', false],
    ['friday', false],
    ['saturday', false],
    ['sunday', false]
  ]) as Map<Day, boolean>

  static initialState: RecurringOrderType = ImmutableMapFactory<RecurringOrderProps>({
    name: '',
    frequency: 'WEEKLY',
    startDate: '',
    endDateEnabled: false,
    endDate: '',
    holidays: false,
    dispatchAsGroup: false,
    paidTogether: false,
    fixedOrder: false,
    days: RecurringOrderForm.daysInitialState,
    orders: List()
  })

  onSubmit = (values: RecurringOrderType) => {
    const timer = setTimeout(() => this.setState({ submitting: true }), 200)
    this.props.onSubmit(values).finally(() => {
      clearTimeout(timer)
      this.setState({ submitting: false })
    })
  }

  updatePaidTogether = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dispatchAsGroup = e.target.checked
    const paidTogether = this.props.formValues.get('paidTogether')
    const fixedOrder = this.props.formValues.get('fixedOrder')
    this.props.mergeFormValues(
      'recurringOrderForm',
      Map({
        paidTogether: dispatchAsGroup && paidTogether,
        fixedOrder: dispatchAsGroup && fixedOrder
      })
    )
  }

  toggleEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.mergeFormValues(
      'recurringOrderForm',
      Map({
        endDateEnabled: e.target.checked,
        endDate: ''
      })
    )
  }

  toggleFrequency = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ((e.target.value as Frequency) == 'MONTHLY') {
      this.props.mergeFormValues('recurringOrderForm', RecurringOrderForm.daysInitialState)
    }
  }

  render(): React.ReactNode {
    const isMonthly = this.props.formValues.get('frequency') == 'MONTHLY'
    return (
      <Form
        model="recurringOrderForm"
        onSubmit={this.onSubmit}
        className="grid items-center justify-start gap-4 grid-cols-[minmax(8rem,24rem)_minmax(8rem,12rem)_auto] grid-rows-3"
      >
        <LabelNew htmlFor="recurringOrderForm.name" style={{ gridRowStart: 1 }}>
          <LabelText>{i18next.t('instant.recurring.name')}</LabelText>
        </LabelNew>
        <div className="row-start-2">
          <Control.text
            model=".name"
            id="recurringOrderForm.name"
            component={Input}
            placeholder={i18next.t('instant.recurring.name')}
            validators={{ required }}
            style={{
              height: '40px'
            }}
          />
          <Errors
            className="errors"
            model=".name"
            show="touched"
            wrapper={SmallValidationError}
            messages={{ required: i18next.t('application.required') }}
          />
        </div>
        <LabelNew htmlFor="recurringOrderForm.frequency" style={{ gridRowStart: 1 }}>
          <LabelText>{i18next.t('instant.recurring.frequency')}</LabelText>
        </LabelNew>
        <Select expand white style={{ gridRowStart: 2 }} className="min-w-32">
          <Control.select
            model=".frequency"
            id="recurringOrderForm.frequency"
            defaultValue={'weekly'}
            style={{
              color: vars.colors.black,
              height: '40px'
            }}
            onChange={this.toggleFrequency}
          >
            {frequencies.map((f) => (
              <option key={f} value={f}>
                {i18next.t(`instant.recurring.frequencies.${f.toLowerCase()}`)}
              </option>
            ))}
          </Control.select>
        </Select>
        {!isMonthly && (
          <>
            <LabelNew htmlFor="recurringOrderForm.day" style={{ gridRowStart: 1 }}>
              <LabelText>{i18next.t('instant.recurring.daysOfWeek')}</LabelText>
            </LabelNew>
            <div className="row-start-2 min-w-64">
              <div className="grid grid-col-5">
                <DayCheckbox day="monday" className="col-start-1" />
                <DayCheckbox day="tuesday" className="col-start-2" />
                <DayCheckbox day="wednesday" className="col-start-3" />
                <DayCheckbox day="thursday" className="col-start-4" />
                <DayCheckbox day="friday" className="col-start-5" />
                <DayCheckbox day="saturday" className="col-start-1" />
                <DayCheckbox day="sunday" className="col-start-2" />
              </div>
            </div>
          </>
        )}
        <LabelNew htmlFor="recurringOrderForm.startDate" className="row-start-1">
          <LabelText>{i18next.t('instant.recurring.startDate')}</LabelText>
        </LabelNew>
        <div className="row-start-2">
          <Control.input
            model=".startDate"
            id={'recurringOrderForm.startDate'}
            component={FormDate}
            validators={{ required }}
            name="date"
            icon={<IconFa icon={['far', 'calendar-alt']} />}
            value={this.props.formValues.get('startDate')}
            onValueChange={(v: string) => {
              this.props.mergeFormValues('recurringOrderForm', Map({ startDate: v }))
            }}
            lang={userLanguage()}
          />
          <Errors
            className="errors"
            model=".startDate"
            show="touched"
            wrapper={SmallValidationError}
            messages={{ required: i18next.t('application.required') }}
          />
        </div>
        {isMonthly && (
          <div className="row-start-3 col-start-3 max-w-56">{i18next.t('instant.recurring.startDateExplanation')}</div>
        )}
        <LabelNew htmlFor="recurringOrderForm.endDate" style={{ gridRowStart: 1 }}>
          <Control.checkbox
            model=".endDateEnabled"
            id={'recurringOrderForm.endDateEnabled'}
            onChange={this.toggleEndDate}
          />
          <CheckboxText>{i18next.t('instant.recurring.endDate')}</CheckboxText>
        </LabelNew>
        <div className="min-w-40" style={{ gridRowStart: 2 }}>
          <Control.input
            model=".endDate"
            id={'recurringOrderForm.endDate'}
            component={FormDate}
            disabled={!this.props.formValues.get('endDateEnabled')}
            icon={<IconFa icon={['far', 'calendar-alt']} />}
            value={this.props.formValues.get('endDate')}
            onValueChange={(v: string) => {
              this.props.mergeFormValues('recurringOrderForm', Map({ endDate: v }))
            }}
            lang={userLanguage()}
            disabledDate={(date: Date) => {
              const startDate = parseISO(this.props.formValues.get('startDate'))
              return isBefore(date, startDate)
            }}
          />
        </div>
        <div className="min-w-44 row-start-1 row-end-4 flex flex-col gap-4">
          <div>
            <LabelNew>
              <Control.checkbox model=".holidays" id={'recurringOrderForm.holidays'} />
              <CheckboxText>{i18next.t('instant.recurring.includeHolidays')}</CheckboxText>
            </LabelNew>
          </div>
          <div>
            <LabelNew htmlFor="recurringOrderForm.dispatchAsGroup">
              <Control.checkbox
                model=".dispatchAsGroup"
                id={'recurringOrderForm.dispatchAsGroup'}
                onChange={this.updatePaidTogether}
              />
              <CheckboxText>{i18next.t('instant.recurring.dispatchAsGroup')}</CheckboxText>
            </LabelNew>
          </div>
          <div>
            <LabelNew htmlFor="recurringOrderForm.paidTogether">
              <Control.checkbox
                model=".paidTogether"
                id={'recurringOrderForm.paidTogether'}
                disabled={!this.props.formValues.get('dispatchAsGroup')}
              />
              <CheckboxText>{i18next.t('instant.paidTogether')}</CheckboxText>
            </LabelNew>
          </div>
          <div>
            <LabelNew htmlFor="recurringOrderForm.fixedOrder">
              <Control.checkbox
                model=".fixedOrder"
                id={'recurringOrderForm.fixedOrder'}
                disabled={!this.props.formValues.get('dispatchAsGroup')}
              />
              <CheckboxText>{i18next.t('instant.fixedOrder')}</CheckboxText>
            </LabelNew>
          </div>
        </div>
        <AcceptButton
          className="whitespace-nowrap"
          disabled={this.state.submitting}
          type="submit"
          style={{ gridRowStart: 2 }}
        >
          {i18next.t('instant.recurring.save')}
        </AcceptButton>
      </Form>
    )
  }
}

const DayCheckbox: React.FC<{ day: Day; className?: string }> = ({ day, className }) => (
  <LabelNew className={classNames(className, 'flex whitespace-nowrap')}>
    <Control.checkbox model={`.${day}`} id={`recurringOrderForm.${day}`} value={day} />
    <CheckboxText>{i18next.t(`weekdaysShort.${day}`)}</CheckboxText>
  </LabelNew>
)

export const formatDate = formatterFor('yyyy-MM-dd')

function formatterFor(format: string = 'yyyy-MM-dd') {
  return (date: Date) => DateTime.fromJSDate(date).toFormat(format)
}
