import { ConsignmentState } from '@glow/entity-types'
import { Map } from 'immutable'
import {
  WEB_SOCKET_ADD_CONTEXTS,
  WEB_SOCKET_REMOVE_CONTEXTS,
  WEB_SOCKET_SET_CONTEXT,
  WEB_SOCKET_SET_CONTEXT_CONSIGNMENT_STATES,
  WEB_SOCKET_SET_CONTEXT_CUSTOMERS,
  WEB_SOCKET_SET_CONTEXT_DATES,
  WEB_SOCKET_SET_CONTEXT_DEPARTMENTS,
  WEB_SOCKET_SET_FILTERS,
  WEB_SOCKET_SET_TYPES
} from '../actions/actionTypes'
import { CustomerIdType } from '../domain/customer'
import { MainAccessType } from '../domain/mainAccessType'
import { DepartmentIdType } from '../types/coreEntitiesTypes'
import { WebSocketContext, WebSocketContextImmutable, WebSocketContextTypes, WebSocketFilterTypes } from '../ws/wsUtils'

export interface SetContextAction {
  type: typeof WEB_SOCKET_SET_CONTEXT
  departmentIds: DepartmentIdType[]
  dates: string[]
  customerIds: CustomerIdType[]
  contexts: WebSocketContextTypes[]
  types: MainAccessType[]
  filters: WebSocketFilterTypes[]
  consignmentStates?: ConsignmentState[]
}

interface SetContextCustomers {
  type: typeof WEB_SOCKET_SET_CONTEXT_CUSTOMERS
  customerIds: CustomerIdType[]
}

interface SetContextDepartments {
  type: typeof WEB_SOCKET_SET_CONTEXT_DEPARTMENTS
  departmentIds: DepartmentIdType[]
}

interface SetContextDates {
  type: typeof WEB_SOCKET_SET_CONTEXT_DATES
  dates: string[]
}

interface SetContextConsignmentStates {
  type: typeof WEB_SOCKET_SET_CONTEXT_CONSIGNMENT_STATES
  states: ConsignmentState[]
}

interface RemoveContexts {
  type: typeof WEB_SOCKET_REMOVE_CONTEXTS
  contexts: WebSocketContextTypes[]
}

interface AddContexts {
  type: typeof WEB_SOCKET_ADD_CONTEXTS
  contexts: WebSocketContextTypes[]
}

interface SetTypes {
  type: typeof WEB_SOCKET_SET_TYPES
  types: MainAccessType[]
}

interface SetFilters {
  type: typeof WEB_SOCKET_SET_FILTERS
  filters: WebSocketFilterTypes[]
}

type ContextAction =
  | SetContextAction
  | AddContexts
  | SetContextCustomers
  | SetContextDepartments
  | SetContextDates
  | SetTypes
  | RemoveContexts
  | SetFilters
  | SetContextConsignmentStates

const initialState: WebSocketContextImmutable = Map({
  contexts: ['NONE'] as WebSocketContextTypes[],
  customerIds: [],
  dates: [],
  departmentIds: [],
  types: [],
  filters: [],
  consignmentStates: []
} as WebSocketContext)

export default (state = initialState, action: ContextAction) => {
  switch (action.type) {
    case WEB_SOCKET_SET_CONTEXT:
      return state.withMutations((it: WebSocketContextImmutable) =>
        it
          .set('departmentIds', action.departmentIds || state.get('departmentIds'))
          .set('dates', action.dates || state.get('dates'))
          .set('customerIds', action.customerIds || state.get('customerIds'))
          .set('contexts', action.contexts || state.get('contexts'))
          .set('types', action.types || state.get('types'))
          .set('filters', action.filters || state.get('filters'))
          .set('consignmentStates', action.consignmentStates || state.get('consignmentStates'))
      )
    case WEB_SOCKET_ADD_CONTEXTS:
      const newContexts = new Set(state.get('contexts').concat(action.contexts))
      return state.set('contexts', Array.from(newContexts))
    case WEB_SOCKET_REMOVE_CONTEXTS:
      const l = new Set(state.get('contexts'))
      action.contexts.forEach((it) => l.delete(it))
      return state.set('contexts', Array.from(l))
    case WEB_SOCKET_SET_CONTEXT_DATES:
      return state.set('dates', action.dates)
    case WEB_SOCKET_SET_CONTEXT_CUSTOMERS:
      return state.set('customerIds', action.customerIds)
    case WEB_SOCKET_SET_CONTEXT_DEPARTMENTS:
      return state.set('departmentIds', action.departmentIds)
    case WEB_SOCKET_SET_CONTEXT_CONSIGNMENT_STATES:
      return state.set('consignmentStates', action.states)
    case WEB_SOCKET_SET_TYPES:
      return state.set('types', action.types)
    case WEB_SOCKET_SET_FILTERS:
      return state.set('filters', action.filters)
    default:
      return state
  }
}
