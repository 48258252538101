import { Map } from 'immutable'
import {
  MODAL_CLOSE,
  MODAL_CLOSE_KEEP_PAGE_STATE,
  MODAL_OPEN,
  ROUTER_LOCATION_CHANGE_ACTION
} from '../actions/actionTypes'
import { AnyData } from '../actions/creators/baseHelpers'

const initialState = Map()

interface OpenModalAction {
  type: typeof MODAL_OPEN
  className: string
  props: AnyData
  modalName?: string
}

interface CloseModalAction {
  type: typeof MODAL_CLOSE | typeof MODAL_CLOSE_KEEP_PAGE_STATE
}

interface LocationChangeAction {
  type: typeof ROUTER_LOCATION_CHANGE_ACTION
}

type ModalAction = LocationChangeAction | OpenModalAction | CloseModalAction

export const modal = (state = initialState, action: ModalAction) => {
  switch (action.type) {
    case MODAL_OPEN:
      return state.withMutations((s) =>
        s.set('className', action.className).set('props', action.props).set('name', action.modalName)
      )
    case MODAL_CLOSE:
    case MODAL_CLOSE_KEEP_PAGE_STATE:
    case ROUTER_LOCATION_CHANGE_ACTION:
      return initialState
    default:
      return state
  }
}
