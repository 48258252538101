import { fromJS, Map } from 'immutable'
import {
  CLEAR_AGGREGATES,
  DISABLE_JOB,
  ENABLE_JOB,
  GET_JOBS_STATUS,
  GET_SLOTS_AND_CONSIGNMENTS_AGGREGATE,
  GET_UNCONFIRMED_ORDERS_COUNT,
  GET_UNRESOLVED_ADDRESS_COUNT,
  GET_USERS_COUNT
} from '../actions/actionTypes'

const initialState = Map({
  slotsAndConsignments: Map()
})

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case CLEAR_AGGREGATES:
      return initialState
    case GET_USERS_COUNT.SUCCESS:
      return state.withMutations((mState) => {
        return fromJS(action.body).map((v: unknown, k: string) => {
          return mState.setIn(['userCount', parseInt(k, 10)], v)
        })
      })
    case GET_SLOTS_AND_CONSIGNMENTS_AGGREGATE.SUCCESS:
      return state.withMutations((mState) => {
        return fromJS(action.body).map((v: unknown, k: { toString: () => unknown }) => {
          return mState.setIn(['slotsAndConsignments', k.toString()], v)
        })
      })
    case GET_JOBS_STATUS.SUCCESS:
      return state.withMutations((mState) => {
        return fromJS(action.body).map((v: unknown, k: { toString: () => unknown }) => {
          return mState.setIn(['jobsStatus', k.toString()], v)
        })
      })
    case GET_UNRESOLVED_ADDRESS_COUNT.SUCCESS:
      return state.withMutations((mState) => {
        try {
          return fromJS(action.body).map((v: unknown, k: { toString: () => unknown }) => {
            return mState.setIn(['unresolvedAddressCounts', k.toString()], v)
          })
        } catch (error) {
          console.error(
            'Failed to update GET_UNRESOLVED_ADDRESS_COUNT, body',
            JSON.stringify(action.body),
            'error',
            JSON.stringify(error)
          )
        }
      })
    case GET_UNCONFIRMED_ORDERS_COUNT.SUCCESS:
      return state.withMutations((mState) => {
        return fromJS(action.body).map((v: unknown, k: { toString: () => unknown }) => {
          return mState.setIn(['unconfirmedOrdersCount', k.toString()], v)
        })
      })
    case DISABLE_JOB.SUCCESS:
      return state.withMutations((mState) => {
        const jobStatus = fromJS(action.body).get('disableJobs').first()
        const jobName = jobStatus.find((_: any, key: string) => key === 'name')

        return fromJS(jobStatus).map((v: unknown, k: unknown) => {
          return mState.setIn(['jobsStatus', jobName, k], v)
        })
      })
    case ENABLE_JOB.SUCCESS:
      return state.withMutations((mState) => {
        const jobStatus = fromJS(action.body).get('enableJobs').first()
        const jobName = jobStatus.find((_: any, key: string) => key === 'name')

        return fromJS(jobStatus).map((v: unknown, k: unknown) => {
          return mState.setIn(['jobsStatus', jobName, k], v)
        })
      })
    default:
      return state
  }
}
