import { DragEndEvent } from '@glow/common-components'
import i18next from 'i18next'
import { Map } from 'immutable'
import React from 'react'
import { Label } from '../../primitives/Forms'
import { Half } from '../../primitives/Layout'
// @ts-expect-error
import { Control, Errors } from 'react-redux-form/lib/immutable'
import { ValidationError } from '../../primitives/ErrorMessages'
import { GooglePlacesAutocompleteWrapper } from '../GooglePlacesAutompleteWrapper'
import { AbsolutePositionedMap } from '../map-common/AbsolutePositionedMap'

export interface UnitStartStopProps {
  showReturnCourierMap: boolean
  lat: number
  lng: number
  countryCode: string
}

export const UnitStartStop = ({ showReturnCourierMap, lat, lng, countryCode }: UnitStartStopProps) => (
  <div style={{ display: 'flex', clear: 'both' }}>
    <Half marginRight>
      <Label style={{ marginBottom: '1.3rem' }}>
        {i18next.t('courier.defaultStartLocation')}
        <Errors
          className="errors"
          model=".startLocation"
          show="touched"
          wrapper={ValidationError}
          messages={{
            requiredImmutable: `${i18next.t('application.required')}`
          }}
        />
      </Label>
      <UnitLocation model=".startLocation" lat={lat} lng={lng} countryCode={countryCode} />
    </Half>
    <Half marginRight>
      <div>
        {i18next.t('courier.defaultStopLocation')}
        <Label noMargin>
          <Control.checkbox model=".shouldDriverNotReturn" id="shouldDriverNotReturn" />{' '}
          {i18next.t('application.driverReturnToAddress')}
        </Label>
        {showReturnCourierMap && <UnitLocation model=".endLocation" lat={lat} lng={lng} countryCode={countryCode} />}
      </div>
    </Half>
  </div>
)

interface UnitLocationProps {
  model: string
  lat: number
  lng: number
  countryCode: string
}

export const UnitLocation = ({ model, lat, lng, countryCode }: UnitLocationProps) => (
  <>
    <Control
      model={model}
      component={GooglePlacesAutocompleteWrapper}
      countryCode={countryCode}
      searchAroundArea={{ lat, lng }}
      mapProps={{
        onComplete: (props: any) => (address: any) => props.onChange(Map(address.latLng))
      }}
    />
    <br />
    <Control
      model={model}
      component={AbsolutePositionedMap}
      zoom={16}
      interactive
      showCenterMarker
      className="mt-2 !h-[300px]"
      mapProps={{
        center: (props: any) => props.modelValue && props.modelValue.toJS(),
        onDragEnd: (props: any) => (event: DragEndEvent) => {
          props.onChange(Map({ lat: event.target.getLatLng()?.lat, lng: event.target.getLatLng()?.lng }))
        }
      }}
    />
  </>
)
