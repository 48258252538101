import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'

import { fromJS, Map, Set } from 'immutable'
import * as types from '../actions/actionTypes'

import { combineReducers } from 'redux-immutable'

const initialState = Map({ status: 'init', params: Set([]) })

const httpStatusState = (status: string, params: any, error: Map<'status' | 'errors', any> | null = null) =>
  Map({
    lastFetch: new Date(),
    status,
    params,
    lastError: error
  })

const requestFlag = 'request'
const successFlag = 'success'

interface Action {
  params?: any
  type: string
  status?: string
  body?: any
  httpStatuses?: string[]
}

const createHttpStatusReducer =
  ({ REQUEST, SUCCESS, FAILURE }: { REQUEST: string; SUCCESS: string; FAILURE: string }) =>
  (state = initialState, action: Action = { type: '' }) => {
    const existing = state.get('params') as any
    const newParams = fromJS(action.params)
    const updatedParams = newParams ? existing.add(newParams) : existing

    switch (action.type) {
      case types.ROUTER_LOCATION_CHANGE_ACTION:
      case types.HTTP_FAILURE_ERRORS_READ:
        return state.get('lastError') && !state.getIn(['lastError', 'read'])
          ? state.setIn(['lastError', 'read'], true)
          : state

      case REQUEST:
        return httpStatusState(requestFlag, updatedParams)

      case SUCCESS:
        return httpStatusState(successFlag, updatedParams)

      case FAILURE:
        return httpStatusState('failure', updatedParams, fromJS({ status: action.status, errors: action.body }))

      default:
        return state
    }
  }

// Get hold of all actions declared as httpActionTypes, ie with
// REQUEST, RESPONSE, FAILURE, and create a reducer for each
// @ts-ignore
const httpStatusActions = pickBy(types, (val) => val['REQUEST'] !== undefined)
// @ts-ignore
const httpStatusReducers = mapValues(httpStatusActions, (v) => createHttpStatusReducer(v))

const combinedReducers = combineReducers(httpStatusReducers)

export default function (state = Map(), action: Action = { type: '' }) {
  switch (action.type) {
    case types.CLEAR_ALL_HTTP_STATUS:
      return combinedReducers(undefined, action)
    case types.CLEAR_HTTP_STATUS:
      return state.withMutations((mState) =>
        action.httpStatuses?.forEach((httpStatus) => mState.set(httpStatus, initialState))
      )

    default:
      // @ts-ignore
      return combinedReducers(state, action)
  }
}

export const httpDone = (getState: () => { getIn: any }, actionType: { name: string }, queryBody: { params: any }) => {
  const httpStatus = getState().getIn(['httpStatus', actionType.name])
  const params = httpStatus.get('params')
  const status = httpStatus.get('status')
  return (status === successFlag || status === requestFlag) && params.includes(fromJS(queryBody.params))
}
