import 'regenerator-runtime/runtime'
import 'whatwg-fetch'
import './i18n/i18n'

import { internalFrontendSentryAPIKey, isProduction, sentryEnvironment } from '@glow/common'
import '@glow/common/utils/atPolyfill'
import { userLanguage } from '@glow/common/utils/language'
import { initializeSiteAnalytics, initializeSiteMetrics } from '@glow/instrumentation'
import { Settings } from 'luxon'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { fetchUser } from './actions/creators/user'
import { checkRevision } from './checkRevision'
import { App } from './components/App'
import './index.css'
import { store } from './reducers/store'
import { initCourierLocationWatcher } from './utils/courierLocationWatcher'
import { initErrorHandlers } from './utils/errorHandlers'
import { initIconLibrary } from './utils/iconLibrary'

Settings.defaultLocale = userLanguage()

initIconLibrary()
initErrorHandlers()

if (isProduction()) {
  initializeSiteAnalytics()
}

const apiKey = internalFrontendSentryAPIKey()
if (apiKey) {
  initializeSiteMetrics(apiKey, sentryEnvironment(), {
    '/676/planning': 0.1, // STOCKHOLM PAR
    '/676/dispatch': 0.1, // STOCKHOLM PAR
    '/planning': 0.01,
    '/dispatch': 0.01,
    '/live2': 0.01,
    defaultSampleRate: 0.001
  })
}

initCourierLocationWatcher(store)
checkRevision(store.dispatch)

fetchUser()(store.dispatch).catch((err: { body: string }) => {
  if (document.location.pathname.includes('customer')) {
    location.href = JSON.parse(err.body).redirectUrl
  }
})

const container = document.getElementById('app')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(<App />)
