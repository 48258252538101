import i18next from 'i18next'
import { CountryCode } from './country'

export const fallbackLanguage = 'en'
export const userLanguage = () => {
  return i18next.language || navigator.language || fallbackLanguage
}

// Languages set are as documented here - https://developers.google.com/maps/faq#languagesupport
export const getLanguageFromCountry = (countryCode: CountryCode) => {
  switch (countryCode) {
    case CountryCode.NO:
      return 'no'
    case CountryCode.SE:
      return 'sv'
    case CountryCode.DK:
      return 'da'
    case CountryCode.FI:
      return 'fi'
    case CountryCode.FR:
      return 'fr'
    default:
      return 'en'
  }
}
