import i18next from 'i18next'
import { List, Set } from 'immutable'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { api } from '../../http/httpHelper'
import { OrderCorrectionDetailProps, OrderCorrectionType } from '../../pages/instant/orderCorrectionTypes'
import { RecurringOrderType } from '../../pages/instant/recurringOrderTypes'
import { RoutePointOrder } from '../../query/instantCourierQuery'
import {
  ClearAllFiltersAction,
  ClearFilterAction,
  ClearOptimizeJobResultAction,
  HidePopoverAction,
  SetFilterAction,
  TogglePopoverAction
} from '../../reducers/filterReducer'
import { ConsignmentIdType, Entity, OrderIdType, SlotIdType, UnitIdType } from '../../types/coreEntitiesTypes'
import { AppStateType } from '../../utils/appStateReduxStore'
import {
  ACCEPT_ORDER_CORRECTION,
  ADD_RECURRING_ORDER_EXCEPTIONS,
  APPLY_SUGGESTED_ROUTE,
  CANCEL_SUGGESTED_ROUTE,
  CLEAR_ALL_COLUMN_FILTERS,
  CLEAR_COLUMN_FILTER,
  CLEAR_OPTIMIZE_RESULTS,
  CREATE_OR_UPDATE_RECURRING_ORDER,
  CREATE_OR_UPDATE_RECURRING_ORDERS,
  CREATE_SUGGESTED_ROUTE,
  CREATE_SUGGESTED_ROUTE_WITH_CONSIGNMENTS,
  DELETE_RECURRING_ORDER,
  DELETE_RECURRING_ORDER_EXCEPTIONS,
  DELETE_UNASSIGNED_ROUTE_POINT_SUGGESTIONS,
  EXECUTE_RECURRING_ORDERS,
  GET_RECURRING_ORDERS,
  GET_RECURRING_ORDERS_FOR_DEPARTMENTS,
  GRID_DISABLE_ROWS,
  GRID_INIT,
  GRID_INIT_SORT,
  GRID_RESET_SORT,
  GRID_SELECT_NONE,
  GRID_SELECT_UPDATE,
  GRID_SORT_BY_COLUMN,
  GRID_SORT_DESCENDING,
  GROUP_ORDERS,
  HIDE_POPOVER,
  PREPLAN_CONSIGNMENTS_TO_COURIER,
  REJECT_ORDER_CORRECTION,
  REORDER_SUGGESTED_ROUTE,
  SET_COLUMN_FILTER,
  TOGGLE_POPOVER,
  TOGGLE_SLOT_MANUAL_PLANNING,
  UNGROUP_ORDERS
} from '../actionTypes'
import { fetchIfNotPresent } from './baseHelpers'
import { closeModalAndNotify } from './helpers'
import { DEFAULT_SELECT_COLUMN } from '../../pages/instant/InstantGrid'
import { ImmutableMap } from '../../types/immutableTypes'
import { Break } from '../../components/unit/UnitForm'
import { OptimizingType } from '../../utils/optimizingUtils'

export interface OptimizeSlotProps {
  slot: SlotIdType
  orders: Set<OrderIdType>
  consignments: Set<ConsignmentIdType>
  flexPickups: Set<ConsignmentIdType>
  flexDeliveries: Set<ConsignmentIdType>
  forcedOrderPickups: Set<ConsignmentIdType>
  forcedOrderDeliveries: Set<ConsignmentIdType>
  usePrevious?: boolean
  returnRoutePointSuggestions?: boolean
  optimizeOnRoutePointSuggestionsOnly: boolean
  break?: Break
  ignoreCourierBreak?: boolean
  applySuggestedRoute?: boolean
  optimizingType: OptimizingType
}

export interface OptimizeSlotPayload extends ImmutableMap<OptimizeSlotProps> {}

export const createSuggestedRouteWithExtraConsignments =
  (slotId: SlotIdType, consignmentIds: Set<ConsignmentIdType>, allowSortingErrors: boolean = false) =>
  (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    return dispatch(
      api.asyncCommand(CREATE_SUGGESTED_ROUTE_WITH_CONSIGNMENTS, {
        type: 'CREATE_SUGGESTED_ROUTE_WITH_CONSIGNMENTS',
        payload: {
          slot: slotId,
          consignments: consignmentIds,
          allowSortingErrors
        }
      })
    )
  }

export const routePointsToSuggestedRoute =
  (slotId: SlotIdType) => (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    return dispatch(
      api.asyncCommand(CREATE_SUGGESTED_ROUTE, {
        type: 'CREATE_SUGGESTED_ROUTE',
        payload: {
          slot: slotId
        }
      })
    )
  }

export const reorderSuggestedRoute =
  (slotId: SlotIdType, routePoints: RoutePointOrder[]) => (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    return dispatch(
      api.asyncCommand(REORDER_SUGGESTED_ROUTE, {
        type: 'REORDER_SUGGESTED_ROUTE',
        payload: {
          slot: slotId,
          route: routePoints
        }
      })
    )
  }

export const applySuggestedRouteToSlot =
  (
    slotId: SlotIdType,
    assignInsteadOfOffer: boolean,
    consignmentIdsToUnassign: Set<ConsignmentIdType>,
    deleteBreak: boolean,
    updatedBreak?: Break
  ) =>
  (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    return applySuggestedRouteToSlotRequest(
      slotId,
      consignmentIdsToUnassign,
      assignInsteadOfOffer,
      updatedBreak,
      deleteBreak
    )(dispatch).then(
      (res: Response) =>
        res.ok ? dispatch(closeModalAndNotify(i18next.t('instant.suggestedRouteApplied'), '', 5000, true)) : res,
      (err: any) => console.log('applySuggestedRouteToSlot', err)
    )
  }

export const applySuggestedRouteToSlotRequest =
  (
    slotId: SlotIdType,
    consignmentIdsToUnassign: Set<ConsignmentIdType>,
    assignInsteadOfOffer?: boolean,
    updatedBreak?: Break,
    deleteBreak?: boolean
  ) =>
  (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    return dispatch(
      api.asyncCommand(APPLY_SUGGESTED_ROUTE, {
        type: 'APPLY_SUGGESTED_ROUTE',
        payload: {
          slot: slotId,
          assignToSlot: assignInsteadOfOffer,
          consignmentIdsToUnassign,
          updatedBreak,
          deleteBreak
        }
      })
    )
  }

export const cancelSuggestedRoute =
  (slotId: SlotIdType, closeAndNotify: boolean = true) =>
  (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    dispatch(
      api.asyncCommand(CANCEL_SUGGESTED_ROUTE, {
        type: 'CANCEL_SUGGESTED_ROUTE',
        payload: {
          slot: slotId
        }
      })
    ).then(
      (res: Response) =>
        res.ok && closeAndNotify
          ? dispatch(closeModalAndNotify(i18next.t('instant.suggestedRouteCancelled'), '', 3000, true))
          : res,
      (err: any) => console.log('cancelSuggestedRoute', err)
    )
  }

export const deleteUnassignedRoutePointSuggestions =
  (consignmentIds: Set<number>, callback: () => void) => (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) =>
    dispatch(
      api.asyncCommand(DELETE_UNASSIGNED_ROUTE_POINT_SUGGESTIONS, {
        type: DELETE_UNASSIGNED_ROUTE_POINT_SUGGESTIONS.name,
        payload: { consignments: consignmentIds }
      })
    ).then(
      (res: Response) => (res.ok && callback ? callback() : res),
      (err: any) => console.log('deleteUnassignedRoutePointSuggestions', err)
    )

export const toggleManuallyRoutePlanForSlot =
  (slotId: SlotIdType) => (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    return dispatch(
      api.asyncCommand(TOGGLE_SLOT_MANUAL_PLANNING, { type: 'TOGGLE_SLOT_MANUAL_PLANNING', payload: { slot: slotId } })
    )
  }

export const gridInit = (key: string) => (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
  return dispatch({
    type: GRID_INIT,
    id: key
  })
}

export const gridSelect =
  (key: string, ids: Set<number>, selectAll: boolean, subKey: string = DEFAULT_SELECT_COLUMN) =>
  (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    return dispatch({
      type: GRID_SELECT_UPDATE,
      id: key,
      column: subKey,
      selectedIds: ids,
      allSelected: selectAll
    })
  }

export const gridSelectNone =
  (key: string, subKey: string = DEFAULT_SELECT_COLUMN) =>
  (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    return dispatch({
      type: GRID_SELECT_NONE,
      id: key,
      column: subKey
    })
  }

export const gridSortDescending =
  (key: string, sortDescending: boolean) => (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    return dispatch({
      type: GRID_SORT_DESCENDING,
      id: key,
      sortDescending
    })
  }

export const gridSetSortColumnKey =
  (key: string, columnKey: string | undefined) => (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    return dispatch({
      type: GRID_SORT_BY_COLUMN,
      id: key,
      sortedColumnKey: columnKey
    })
  }

export const gridResetSort = (key: string) => (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
  return dispatch({
    type: GRID_RESET_SORT,
    id: key
  })
}

export const gridInitSort =
  <T>(
    key: string,
    sortedColumnKey: keyof T,
    sortDescending: boolean,
    sortFunction: (d1: Entity<T>, d2: Entity<T>) => number
  ) =>
  (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    return dispatch({
      type: GRID_INIT_SORT,
      id: key,
      sortedColumnKey,
      sortDescending,
      sortFunction
    })
  }

export const gridDisableRows =
  (key: string, disabledIds: Set<number>) => (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    return dispatch({
      type: GRID_DISABLE_ROWS,
      id: key,
      disabledIds
    })
  }

export const rejectOrderCorrection =
  (orderId: OrderIdType, orderCorrectionId: number) => (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    dispatch(
      api.asyncCommand(REJECT_ORDER_CORRECTION, {
        type: 'REJECT_ORDER_CORRECTION',
        payload: {
          orderId,
          id: orderCorrectionId
        }
      })
    ).then(
      (res: Response) =>
        res.ok && dispatch(closeModalAndNotify(i18next.t('instant.corrections.rejected'), '', 1000, true)),
      (err: any) => console.log('rejectOrderCorrection', err)
    )
  }

export const acceptOrderCorrection =
  (values: OrderCorrectionType, orderCorrection: ImmutableMap<OrderCorrectionDetailProps>) =>
  (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    dispatch(
      api.asyncCommand(ACCEPT_ORDER_CORRECTION, {
        type: 'ACCEPT_ORDER_CORRECTION',
        payload: {
          orderId: orderCorrection.get('orderId'),
          id: orderCorrection.get('id'),
          numberOfPackages: values.get('numberOfPackages') || orderCorrection.get('existingPackages') || null,
          volumeDmq: values.get('volumeValue') || null,
          weightKilograms: values.get('weightValue') || null,
          deliveryWaitingTimeInMinutes: values.get('deliveryWaitingTimeInMinutes') || null,
          pickupWaitingTimeInMinutes: values.get('pickupWaitingTimeInMinutes') || null,
          deliveryLoadingTimeInMinutes: values.get('deliveryLoadingTimeInMinutes') || null,
          pickupLoadingTimeInMinutes: values.get('pickupLoadingTimeInMinutes') || null,
          comment: values.get('comment') || null
        }
      })
    ).then(
      (res: Response) =>
        res.ok && dispatch(closeModalAndNotify(i18next.t('instant.corrections.accepted'), '', 1000, true)),
      (err: any) => console.log('acceptOrderCorrection', err)
    )
  }

export const groupOrders =
  (groupId: string, groupName: string, orderIds: List<number>, fixedOrder: boolean, paidTogether: boolean) =>
  (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    const payload = {
      groupId,
      groupName,
      orderIds,
      fixedOrder,
      paidTogether
    }
    return dispatch(
      api.asyncCommand(GROUP_ORDERS, {
        type: GROUP_ORDERS.name,
        payload
      })
    )
  }

export const preplanConsignmentsToCourier =
  (courierId: UnitIdType | null, consignments: Set<number>) =>
  (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    return dispatch(
      api.asyncCommand(PREPLAN_CONSIGNMENTS_TO_COURIER, {
        type: PREPLAN_CONSIGNMENTS_TO_COURIER.name,
        payload: {
          alystraId: courierId,
          consignments: consignments
        }
      })
    )
  }

export const ungroupOrders = (orderIds: List<number>) => (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
  const payload = {
    orderIds
  }
  return dispatch(
    api.asyncCommand(UNGROUP_ORDERS, {
      type: UNGROUP_ORDERS.name,
      payload
    })
  )
}

export const showPopover =
  (gridId: string, column: string) => (dispatch: ThunkDispatch<AppStateType, null, TogglePopoverAction>) => {
    return dispatch({
      type: TOGGLE_POPOVER,
      gridId,
      column
    })
  }

export const hidePopover =
  (gridId: string, column: string) => (dispatch: ThunkDispatch<AppStateType, null, HidePopoverAction>) => {
    return dispatch({
      type: HIDE_POPOVER,
      gridId,
      column
    })
  }

export const setFilter =
  (gridId: string, column: string, filter: string) =>
  (dispatch: ThunkDispatch<AppStateType, null, SetFilterAction>) => {
    return dispatch({
      type: SET_COLUMN_FILTER,
      gridId,
      column,
      filter
    })
  }

export const clearFilter =
  (gridId: string, column: string) => (dispatch: ThunkDispatch<AppStateType, null, ClearFilterAction>) => {
    return dispatch({
      type: CLEAR_COLUMN_FILTER,
      gridId,
      column
    })
  }

export const clearAllFilters =
  (gridId: string) => (dispatch: ThunkDispatch<AppStateType, null, ClearAllFiltersAction>) => {
    return dispatch({
      type: CLEAR_ALL_COLUMN_FILTERS,
      gridId
    })
  }

export const addRecurringOrderExceptions =
  (recurringOrderId: number, orderIds: List<number>, startDate: string, endDate: string) =>
  (dispatch: ThunkDispatch<AppStateType, null, ClearAllFiltersAction>) => {
    return dispatch(
      api.asyncCommand(ADD_RECURRING_ORDER_EXCEPTIONS, {
        type: ADD_RECURRING_ORDER_EXCEPTIONS.name,
        payload: { recurringOrderId, orderIds, startDate, endDate }
      })
    )
  }

export const deleteRecurringOrderExceptions =
  (id: number) => (dispatch: ThunkDispatch<AppStateType, null, ClearAllFiltersAction>) => {
    return dispatch(
      api.asyncCommand(DELETE_RECURRING_ORDER_EXCEPTIONS, {
        type: DELETE_RECURRING_ORDER_EXCEPTIONS.name,
        payload: { id }
      })
    )
  }

export const createOrUpdateRecurringOrder =
  (order: RecurringOrderType) => (dispatch: ThunkDispatch<AppStateType, null, ClearAllFiltersAction>) => {
    return dispatch(
      api.asyncCommand(CREATE_OR_UPDATE_RECURRING_ORDER, {
        type: CREATE_OR_UPDATE_RECURRING_ORDER.name,
        payload: order.toJS()
      })
    )
  }

export const createOrUpdateRecurringOrders =
  (recurringOrderIds: number[], orderIds: number[]) =>
  (dispatch: ThunkDispatch<AppStateType, null, ClearAllFiltersAction>) => {
    return dispatch(
      api.asyncCommand(CREATE_OR_UPDATE_RECURRING_ORDERS, {
        type: CREATE_OR_UPDATE_RECURRING_ORDERS.name,
        payload: { recurringOrderIds, orderIds }
      })
    )
  }

export function deleteRecurringOrder(id: number) {
  return (dispatch: ThunkDispatch<AppStateType, null, ClearAllFiltersAction>) => {
    return dispatch(api.asyncDeleteCommand(DELETE_RECURRING_ORDER, { type: 'DELETE_RECURRING_ORDER', payload: { id } }))
  }
}

export function getRecurringOrders(departmentId: number) {
  const params = { department: departmentId }

  return fetchIfNotPresent(GET_RECURRING_ORDERS, { query: 'recurringOrders', params })
}
export function getRecurringOrdersForDepartments(departmentIds: number[]) {
  const params = { departmentIds: departmentIds }

  return fetchIfNotPresent(GET_RECURRING_ORDERS_FOR_DEPARTMENTS, { query: 'recurringOrdersForDepartmentIds', params })
}

export function executeRecurringOrders(date: string, departmentId: number) {
  return (dispatch: ThunkDispatch<AppStateType, null, ClearAllFiltersAction>) => {
    return dispatch(
      api.asyncCommand(EXECUTE_RECURRING_ORDERS, {
        type: 'EXECUTE_RECURRING_ORDERS',
        payload: {
          date,
          department: departmentId
        }
      })
    )
  }
}

export const resetOptimizeResults =
  () => (dispatch: ThunkDispatch<AppStateType, null, ClearOptimizeJobResultAction>) => {
    return dispatch({
      type: CLEAR_OPTIMIZE_RESULTS
    })
  }
