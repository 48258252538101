import { UserAccessKey } from '@glow/entity-types'
import { plannerTypesWithWriteAccess, ROLE_ADMIN } from '../utils/roles'

export interface ActionType {
  name: string
  REQUEST: string
  SUCCESS: string
  FAILURE: string
  isEntity: boolean
}
export interface ActionTypeWithAccess {
  name: string
  REQUEST: string
  SUCCESS: string
  FAILURE: string
  isEntity: boolean
  query: string
  userAccessKeys: UserAccessKey[]
  roles: string[]
}

const httpActionTypes = (baseActionType: string, isEntity: boolean = false): ActionType => ({
  name: baseActionType,
  REQUEST: baseActionType + '_REQUEST',
  SUCCESS: baseActionType + '_SUCCESS',
  FAILURE: baseActionType + '_FAILURE',
  isEntity
})

const httpActionWithAccessTypes = (
  baseActionType: string,
  query: string,
  userAccessKeys: UserAccessKey[],
  roles: string[],
  isEntity: boolean = false
): ActionTypeWithAccess => ({
  name: baseActionType,
  REQUEST: baseActionType + '_REQUEST',
  SUCCESS: baseActionType + '_SUCCESS',
  FAILURE: baseActionType + '_FAILURE',
  isEntity,
  query,
  userAccessKeys,
  roles
})

export const COPY_DEVIATED_ORDER = httpActionTypes('COPY_DEVIATED_ORDER', true)
export const GET_CONSIGNMENTS = httpActionTypes('GET_CONSIGNMENTS', true)
export const GET_CONSIGNMENT_EVENTS = httpActionTypes('GET_CONSIGNMENT_EVENTS', true)
export const GET_LATEST_CONSIGNMENT_EVENT_ACTIONS = httpActionTypes('GET_LATEST_CONSIGNMENT_EVENT_ACTIONS', true)
export const CREATE_CONSIGNMENT_EVENT_ACTION = httpActionTypes('CREATE_CONSIGNMENT_EVENT_ACTION', true)
export const SEARCH_CONSIGNMENTS = httpActionTypes('SEARCH_CONSIGNMENTS', true)
export const GET_CONSIGNMENTS_FOR_INVOICE_HANDLING = httpActionTypes('GET_CONSIGNMENTS_FOR_INVOICE_HANDLING', true)
export const GET_CONSIGNMENTS_FOR_RETURN_HANDLING = httpActionTypes('GET_CONSIGNMENTS_FOR_RETURN_HANDLING', true)
export const GET_CONSIGNMENTS_FOR_PRE_ADVICE_FOLLOWUP = httpActionTypes(
  'GET_CONSIGNMENTS_FOR_PRE_ADVICE_FOLLOWUP',
  true
)
export const GET_ORDERS_COUNT_FOR_PRE_ADVICE_FOLLOWUP = httpActionTypes(
  'GET_ORDERS_COUNT_FOR_PRE_ADVICE_FOLLOWUP',
  true
)
export const SEARCH_ADDRESSES = httpActionTypes('SEARCH_ADDRESSES', true)
export const DELETE_ADDRESSES = httpActionTypes('DELETE_ADDRESSES', true)
export const SEARCH_CUSTOMERS = httpActionTypes('SEARCH_CUSTOMERS', false)
export const SEARCH_ORDERS = httpActionTypes('SEARCH_ORDERS', true)
export const GET_SUBCUSTOMERS = httpActionTypes('GET_SUBCUSTOMERS', false)
export const GET_UNRESOLVED_ADDRESSES = httpActionTypes('GET_UNRESOLVED_ADDRESSES')
export const GET_SERVICES_AND_VASES_TEXTS = httpActionTypes('GET_SERVICES_AND_VASES_TEXTS')
export const GET_UNRESOLVED_ADDRESS_COUNT = httpActionTypes('GET_UNRESOLVED_ADDRESS_COUNT')
export const GET_UNCONFIRMED_ORDERS_COUNT = httpActionTypes('GET_UNCONFIRMED_ORDERS_COUNT')
export const GET_UNCONFIRMED_ORDERS = httpActionTypes('GET_UNCONFIRMED_ORDERS', true)
export const ACCEPT_ORDERS = httpActionTypes('ACCEPT_ORDERS', true)
export const REJECT_ORDERS = httpActionTypes('REJECT_ORDERS', true)
export const RESOLVE_ADDRESS = httpActionTypes('RESOLVE_ADDRESS', true)
export const GET_DEPARTMENTS = httpActionTypes('GET_DEPARTMENTS', true)
export const GET_DEPARTMENT_TIME_WINDOWS = httpActionTypes('GET_DEPARTMENT_TIME_WINDOWS', true)
export const UPSERT_DEPARTMENT_TIME_WINDOW = httpActionTypes('UPSERT_DEPARTMENT_TIME_WINDOW', true)
export const GET_ALL_MATRICES = httpActionTypes('GET_ALL_MATRICES')
export const GET_MATRIX = httpActionTypes('GET_MATRIX')
export const LIST_CAPACITY = httpActionTypes('LIST_CAPACITY')
export const GET_CAPACITY = httpActionTypes('GET_CAPACITY')
export const UPDATE_CAPACITY = httpActionTypes('UPDATE_CAPACITY')
export const GET_TIMEWINDOWS = httpActionTypes('GET_TIMEWINDOWS')
export const GET_CHECKOUT_CUSTOMERS = httpActionTypes('GET_CHECKOUT_CUSTOMERS')
export const UPSERT_CUSTOMER_MATRICES = httpActionTypes('UPSERT_CUSTOMER_MATRICES')
export const GET_CUSTOMER_MATRICES = httpActionTypes('GET_CUSTOMER_MATRICES')
export const UPLOAD_MATRIX = httpActionTypes('UPLOAD_MATRIX')
export const DELETE_MATRIX = httpActionTypes('DELETE_MATRIX')
export const CREATE_MATRIX = httpActionTypes('CREATE_MATRIX')
export const UPDATE_MATRIX = httpActionTypes('UPDATE_MATRIX')
export const LIST_MATRIX = httpActionTypes('LIST_MATRIX')
export const GET_ALL_DEPARTMENTS = httpActionTypes('GET_ALL_DEPARTMENTS', true)
export const GET_JOBS_STATUS = httpActionTypes('GET_JOBS_STATUS')
export const GET_HOLIDAYS_DATA = httpActionTypes('GET_HOLIDAYS_DATA', true)
export const DELETE_HOLIDAY = httpActionTypes('DELETE_HOLIDAY', true)
export const PREPLAN_CONSIGNMENTS_TO_COURIER = httpActionTypes('PREPLAN_CONSIGNMENTS_TO_COURIER', true)
export const CREATE_HOLIDAY = httpActionTypes('CREATE_HOLIDAY', true)
export const GET_NUMBER_SERIES = httpActionTypes('GET_NUMBER_SERIES', true)
export const GET_USER = httpActionTypes('GET_USER')
export const GET_USER_BY_ID = httpActionTypes('GET_USER_BY_ID', true)
export const LOGOUT = httpActionTypes('LOGOUT')
export const GET_VEHICLES = httpActionTypes('GET_VEHICLES', true)
export const CREATE_VEHICLE = httpActionTypes('CREATE_VEHICLE', true)
export const UPDATE_VEHICLE_INFORMATION = httpActionTypes('UPDATE_VEHICLE_INFORMATION', true)
export const UNASSIGN_VEHICLE_FROM_DEPARTMENT = httpActionTypes('UNASSIGN_VEHICLE_FROM_DEPARTMENT', true)
export const GET_SLOTS = httpActionTypes('GET_SLOTS', true)
export const GET_SLOTS_BY_UNIT_ID = httpActionTypes('GET_SLOTS_BY_UNIT_ID', true)
export const GET_SLOT = httpActionTypes('GET_SLOT', true)
export const GET_ORDER = httpActionTypes('GET_ORDER', true)
export const GET_DELETED_ORDER = httpActionTypes('GET_DELETED_ORDER', true)
export const GET_ORDER_BY_SHIPMENT_ID = httpActionTypes('GET_ORDER_BY_SHIPMENT_ID', true)
export const GET_ORDERS_BY_SHIPMENT_IDS = httpActionTypes('GET_ORDERS_BY_SHIPMENT_IDS', true)
export const GET_ORDERS_BY_RECURRING_ORDER_ID = httpActionTypes('GET_ORDERS_BY_RECURRING_ORDER_ID', true)
export const GET_ORDERS_BY_GROUP_ID = httpActionTypes('GET_ORDERS_BY_GROUP_ID', true)
export const GET_ORDERS_BY_MULTILEG_IDS = httpActionTypes('GET_ORDERS_BY_MULTILEG_IDS', true)
export const GET_SLOTS_AND_CONSIGNMENTS_AGGREGATE = httpActionTypes('GET_SLOTS_AND_CONSIGNMENTS_AGGREGATE')
export const GET_ROUTE_POINTS = httpActionTypes('GET_ROUTE_POINTS', true)
export const GET_USERS_COUNT = httpActionTypes('GET_USERS_COUNT', false)
export const GET_CUSTOMS_AGENTS = httpActionTypes('GET_CUSTOMS_AGENTS', true)
export const GET_HANDLERS = httpActionTypes('GET_HANDLERS', true)
export const GET_PLANNERS = httpActionTypes('GET_PLANNERS', true)
export const GET_ADMINS = httpActionTypes('GET_ADMINS', true)
export const GET_READ_ONLY_PLANNERS = httpActionTypes('GET_READ_ONLY_PLANNERS', true)
export const GET_PLUS_PLANNERS = httpActionTypes('GET_PLUS_PLANNERS', true)
export const GET_PARTNERS = httpActionTypes('GET_PARTNERS', true)
export const GET_UNITS = httpActionTypes('GET_UNITS', true)
export const GET_COURIER_LOCATIONS = httpActionTypes('GET_COURIER_LOCATIONS', true)
export const UPDATE_COURIER_INFORMATION = httpActionTypes('UPDATE_COURIER_INFORMATION', true)
export const CREATE_UNIT = httpActionTypes('CREATE_UNIT', true)
export const CREATE_HANDLER_USER = httpActionTypes('CREATE_HANDLER_USER', true)
export const UPDATE_HANDLER_USER = httpActionTypes('UPDATE_HANDLER_USER', true)
export const CREATE_CUSTOMS_AGENT = httpActionTypes('CREATE_CUSTOMS_AGENT_USER', true)
export const UPDATE_CUSTOMS_AGENT = httpActionTypes('UPDATE_CUSTOMS_AGENT_USER', true)
export const GET_CUSTOMER_USERS = httpActionTypes('GET_CUSTOMER_USERS', true)
export const CREATE_PLANNER = httpActionTypes('CREATE_PLANNER', true)
export const CREATE_DEPARTMENT = httpActionTypes('CREATE_DEPARTMENT', true)
export const UPDATE_DEPARTMENT = httpActionTypes('UPDATE_DEPARTMENT', true)
export const UPDATE_PLANNER = httpActionTypes('UPDATE_PLANNER', true)
export const ADD_ROLES_TO_USER = httpActionTypes('ADD_ROLES_TO_USER', true)
export const REMOVE_ROLES_FROM_USER = httpActionTypes('REMOVE_ROLES_FROM_USER', true)
export const CREATE_ROUTE = httpActionTypes('CREATE_ROUTE', true)
export const UPDATE_ROUTE = httpActionTypes('UPDATE_ROUTE', true)
export const DELETE_SLOT = httpActionTypes('DELETE_SLOT', true)
export const DELETE_USER = httpActionTypes('DELETE_USER', true)
export const COMPLETE_SLOT_PLANNING = httpActionTypes('COMPLETE_SLOT_PLANNING', true)
export const DELETE_ORDERS = httpActionTypes('DELETE_ORDERS', true)
export const CREATE_ORDER_OPTIMUS = httpActionTypes('CREATE_ORDER_OPTIMUS', false)
export const UPDATE_AREA_IN_SLOT = httpActionTypes('UPDATE_AREA_IN_SLOT', true)
export const REMOVE_AREA_FROM_SLOT = httpActionTypes('REMOVE_AREA_FROM_SLOT', true)
export const OPTIMIZE_SLOT_ON_SHIPMENT_LEVEL = httpActionTypes('OPTIMIZE_SLOT_ON_SHIPMENT_LEVEL', true)
export const OPTIMIZE_SLOTS_ON_SHIPMENT_LEVEL = httpActionTypes('OPTIMIZE_SLOTS_ON_SHIPMENT_LEVEL', true)
export const CANCEL_RUNNING_OPTIMIZING = httpActionTypes('CANCEL_RUNNING_OPTIMIZING', true)
export const CREATE_SUGGESTED_ROUTE_WITH_CONSIGNMENTS = httpActionTypes(
  'CREATE_SUGGESTED_ROUTE_WITH_CONSIGNMENTS',
  true
)
export const REVERSED_OPTIMIZE_SLOT = httpActionTypes('REVERSED_OPTIMIZE_SLOT', true)
export const CLEAR_OPTIMIZE_RESULTS = 'CLEAR_OPTIMIZE_RESULTS'
export const CLEAR_ORDER_SEARCHS = 'CLEAR_ORDER_SEARCHS'
export const CREATE_NUMBER_SERIES = httpActionTypes('CREATE_NUMBER_SERIES', true)
export const DELETE_NUMBER_SERIES = httpActionTypes('DELETE_NUMBER_SERIES', true)
export const GET_OPTIMIZE_REQUEST = httpActionTypes('GET_OPTIMIZE_REQUEST', true)
export const GET_ORDER_RETURN_DETAILS = httpActionTypes('GET_ORDER_RETURN_DETAILS', true)
export const SET_COURIER_LOCATION = httpActionTypes('SET_COURIER_LOCATION', true)
export const UPDATE_CONSIGNMENTS_PICKUP_LOCATION = httpActionTypes('UPDATE_CONSIGNMENTS_PICKUP_LOCATION', true)
export const SET_CONSIGNMENT_SLOT = httpActionTypes('SET_CONSIGNMENT_SLOT', true)
export const UNASSIGN_CONSIGNMENT_FROM_SLOT = httpActionTypes('UNASSIGN_CONSIGNMENT_FROM_SLOT', true)
export const UNASSIGN_BULK_CONSIGNMENTS_FROM_SLOTS = httpActionTypes('UNASSIGN_BULK_CONSIGNMENTS_FROM_SLOTS', true)
export const UNASSIGN_CONSIGNMENTS = httpActionTypes('UNASSIGN_CONSIGNMENTS', true)
export const DELETE_BREAK_FROM_ROUTE = httpActionTypes('DELETE_BREAK_FROM_ROUTE', true)
export const FORCE_UNASSIGN_CONSIGNMENTS = httpActionTypes('FORCE_UNASSIGN_CONSIGNMENTS', true)
export const CREATE_WAYBILLS_REQUEST = httpActionTypes('CREATE_WAYBILLS_REQUEST')
export const GET_WAYBILLS = httpActionTypes('GET_WAYBILLS')

export const GET_DELIVERY_NOTES = httpActionTypes('GET_DELIVERY_NOTES')
export const DELIVER_CONSIGNMENT_MANUAL_OVERRIDE = httpActionTypes('DELIVER_CONSIGNMENT_MANUAL_OVERRIDE', true)
export const COLLECT_CONSIGNMENTS_MANUAL_OVERRIDE = httpActionTypes('COLLECT_CONSIGNMENTS_MANUAL_OVERRIDE', true)
export const RETURN_CONSIGNMENTS_MANUAL_OVERRIDE = httpActionTypes('RETURN_CONSIGNMENTS_MANUAL_OVERRIDE', true)
export const DEVIATE_CONSIGNMENT_MANUAL_OVERRIDE = httpActionTypes('DEVIATE_CONSIGNMENT_MANUAL_OVERRIDE', true)
export const PRE_ADVICE_MANUAL_ATTEMPT = httpActionTypes('PRE_ADVICE_MANUAL_ATTEMPT', true)
export const MANUAL_PRE_ADVICE = httpActionTypes('MANUAL_PRE_ADVICE', true)
export const ADD_SCANNED_EVENTS = httpActionTypes('ADD_SCANNED_EVENTS', true)
export const ADD_SORTED_EVENTS = httpActionTypes('ADD_SORTED_EVENTS', true)
export const MARK_NOT_ARRIVED_AT_DISTRIBUTING_TERMINAL = httpActionTypes(
  'MARK_NOT_ARRIVED_AT_DISTRIBUTING_TERMINAL',
  true
)
export const UNRESOLVE_DELIVERY_ADDRESS = httpActionTypes('UNRESOLVE_DELIVERY_ADDRESS', true)
export const UNRESOLVE_PICKUP_ADDRESS = httpActionTypes('UNRESOLVE_PICKUP_ADDRESS', true)
export const UPLOAD_EXCEL_ORDERS_V2 = httpActionTypes('UPLOAD_EXCEL_ORDERS_V2', false)
export const UPLOAD_EXCEL_ADDRESSES = httpActionTypes('UPLOAD_EXCEL_ADDRESSES', false)
export const UPLOAD_EXCEL_RECIPIENTS = httpActionTypes('UPLOAD_EXCEL_RECIPIENTS', false)
export const UPLOAD_EXCEL_TIME_MATRIX = httpActionTypes('UPLOAD_EXCEL_TIME_MATRIX', false)
export const UPDATE_PICKUP_AND_DELIVERY_TIMES = httpActionTypes('UPDATE_PICKUP_AND_DELIVERY_TIMES', false)
export const GET_CUSTOMERS_WITH_TIME_MATRIX = httpActionTypes('GET_CUSTOMERS_WITH_TIME_MATRIX', false)
export const DELETE_TIME_MATRIX = httpActionTypes('DELETE_TIME_MATRIX', false)
export const TOGGLE_SHOW_CUSTOMER_PRICE = httpActionTypes('TOGGLE_SHOW_CUSTOMER_PRICE', false)
export const GET_ALL_CUSTOMERS = httpActionTypes('GET_ALL_CUSTOMERS', true)
export const GET_CUSTOMER = httpActionTypes('GET_CUSTOMER', true)
export const GET_CUSTOMER_RETURN_ADDRESS = httpActionTypes('GET_CUSTOMER_RETURN_ADDRESS', true)
export const GET_CUSTOMER_INVOICE_SPLIT = httpActionTypes('GET_CUSTOMER_INVOICE_SPLIT', true)
export const GET_CUSTOMER_EXTERNAL_CUSTOMER_NUMBERS = httpActionTypes('GET_CUSTOMER_EXTERNAL_CUSTOMER_NUMBERS', true)
export const GET_DELIVERY_WINDOWS = httpActionTypes('GET_DELIVERY_WINDOWS', true)
export const GET_SERVICES_OPTIMUS = httpActionTypes('GET_SERVICES_OPTIMUS', false)
export const GET_HD_SERVICES_CHECKOUT = httpActionTypes('GET_HD_SERVICES_CHECKOUT', false)
export const GET_PICKUP_SERVICES_OPTIMUS = httpActionTypes('GET_PICKUP_SERVICES_OPTIMUS', false)
export const GET_DELIVERY_SERVICES_OPTIMUS = httpActionTypes('GET_DELIVERY_SERVICES_OPTIMUS', false)
export const GET_DEPARTMENT_SERVICES_OPTIMUS = httpActionTypes('GET_DEPARTMENT_SERVICES_OPTIMUS', false)
export const GET_TEMPLATES = httpActionTypes('GET_TEMPLATES', true)
export const CREATE_OR_UPDATE_TEMPLATE = httpActionTypes('CREATE_OR_UPDATE_TEMPLATE', true)
export const SET_DEPARTMENT_DEFAULT_TEMPLATES = httpActionTypes('SET_DEPARTMENT_DEFAULT_TEMPLATES', true)
export const CREATE_SLOTS_FROM_TEMPLATE = httpActionTypes('CREATE_SLOTS_FROM_TEMPLATE', true)
export const DELETE_TEMPLATE = httpActionTypes('DELETE_TEMPLATE', true)
export const CLAIM_EMAIL = httpActionTypes('CLAIM_EMAIL')
export const GET_SERVICE_MAPPING_DATA = httpActionTypes('GET_SERVICE_MAPPING_DATA', true)
export const ADD_UPDATE_SERVICE_MAPPING = httpActionTypes('ADD_UPDATE_SERVICE_MAPPING', true)
export const GET_TEXTS = httpActionTypes('GET_TEXTS', true)
export const GET_UPSALES_RULES = httpActionTypes('GET_UPSALES_RULES', true)
export const GET_UPSALES_RULE_BY_ID = httpActionTypes('GET_UPSALES_RULE_BY_ID', true)
export const ADD_UPSALES_RULE = httpActionTypes('ADD_UPSALES_RULE', true)
export const UPDATE_UPSALES_RULE = httpActionTypes('UPDATE_UPSALES_RULE', true)
export const DELETE_UPSALES_RULE = httpActionTypes('DELETE_UPSALES_RULE', true)
export const ENABLE_UPSALES_RULE = httpActionTypes('ENABLE_UPSALES_RULE', true)
export const DISABLE_UPSALES_RULE = httpActionTypes('DISABLE_UPSALES_RULE', true)

export const GET_CUSTOMER_MIGRATION_STATUS = httpActionTypes('GET_CUSTOMER_MIGRATION_STATUS', true)
export const MIGRATE_CUSTOMER = httpActionTypes('MIGRATE_CUSTOMER', true)

export const GET_TEXT_BY_ID = httpActionTypes('GET_TEXT_BY_ID', true)
export const ADD_TEXT = httpActionTypes('ADD_TEXT', true)
export const UPDATE_TEXT = httpActionTypes('UPDATE_TEXT', true)
export const DELETE_TEXT = httpActionTypes('DELETE_TEXT', true)
export const EXPORT_DEPARTMENT_DATA = httpActionTypes('EXPORT_DEPARTMENT_DATA', true)
export const GET_EXPORT_DEPARTMENT_DATA_EXECUTIONS = httpActionTypes('GET_EXPORT_DEPARTMENT_DATA_EXECUTIONS', true)
export const GET_IMPORT_DEPARTMENT_DATA_EXECUTIONS = httpActionTypes('GET_IMPORT_DEPARTMENT_DATA_EXECUTIONS', true)
export const GET_EXPORT_DEPARTMENT_GROUP_DATA_EXECUTIONS = httpActionTypes(
  'GET_EXPORT_DEPARTMENT_GROUP_DATA_EXECUTIONS',
  true
)

export const DELETE_SERVICE_MAPPING = httpActionTypes('DELETE_SERVICE_MAPPING', true)

export const GET_OPTIMIZING_DATA = httpActionTypes('GET_OPTIMIZING_DATA')
export const GET_OPTIMIZING_REQUEST_DATA = httpActionTypes('GET_OPTIMIZING_REQUEST_DATA')
export const GET_OPTIMIZING_RESPONSE_DATA = httpActionTypes('GET_OPTIMIZING_RESPONSE_DATA')
export const GET_OPTIMIZING_ERROR_DATA = httpActionTypes('GET_OPTIMIZING_ERROR_DATA')
export const GET_APOLLO_AUDIT_LOG = httpActionTypes('GET_APOLLO_AUDIT_LOG')

export const SET_PASSWORD = httpActionTypes('SET_PASSWORD')
export const REQUEST_PWD_RESET = httpActionTypes('REQUEST_PWD_RESET')
export const VERIFY_PWD = httpActionTypes('VERIFY_PWD')

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION'
export const DISMISS_ALL_NOTIFICATIONS = 'DISMISS_ALL_NOTIFICATIONS'

export const DISMISS_FEEDBACK_NOTIFICATION = 'DISMISS_FEED_BACK_NOTIFICATION'
export const ADD_FEEDBACK_NOTIFICATION = 'ADD_FEEDBACK_NOTIFICATION'

export const REQUEST_TOKEN = httpActionTypes('REQUEST_TOKEN')
export const VERIFY_TOKEN = httpActionTypes('VERIFY_TOKEN')
export const CLEAR_TOKEN_STATES = 'CLEAR_TOKEN_STATES'

export const PAGE_STATE_ADD = 'PAGE_STATE_ADD'

export const PAGE_STATE_CLEAR = 'PAGE_STATE_CLEAR'

export const CLEAR_ALL_HTTP_STATUS = 'CLEAR_ALL_HTTP_STATUS'
export const CLEAR_HTTP_STATUS = 'CLEAR_HTTP_STATUS'

export const MODAL_OPEN = 'MODAL_OPEN'
export const MODAL_CLOSE = 'MODAL_CLOSE'
export const MODAL_CLOSE_KEEP_PAGE_STATE = 'MODAL_CLOSE_KEEP_PAGE_STATE'

export const DRAWER_OPEN = 'DRAWER_OPEN'
export const DRAWER_CLOSE = 'DRAWER_CLOSE'

export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST'
export const CREATE_ORDER_OPTIMUS_SUCCESS = 'CREATE_ORDER_OPTIMUS_SUCCESS'
export const BATCH_CONSIGNMENT_FETCH = 'BATCH_CONSIGNMENT_FETCH'

export const ENTITIES_RECEIVED = 'ENTITIES_RECEIVED'
export const CLEAR_ORDER_IDS_ON_MOVED_ORDERS = 'CLEAR_ORDER_IDS_ON_MOVED_ORDERS'

export const SET_MAP_POSITION = 'SET_MAP_POSITION'
export const TOGGLE_MAP_AUTO_PAN = 'TOGGLE_MAP_AUTO_PAN'
export const RE_RENDER_MAP = 'RE_RENDER_MAP'
export const SET_TIME_FILTER = 'SET_TIME_FILTER'

export const SET_FILTER = 'SET_FILTER'

export const SET_SLOT_FILTER = 'SET_SLOT_FILTER'

export const ROUTER_LOCATION_CHANGE_ACTION = '@@router/LOCATION_CHANGE'

export const CLEAR_AGGREGATES = 'CLEAR_AGGREGATES'
export const CLEAR_OPTIMIZING_DATA = 'CLEAR_OPTIMIZING_DATA'
export const CLEAR_APOLLO_AUDIT_LOG = 'CLEAR_APOLLO_AUDIT_LOG'

export const TURN_OFF_WEBSOCKETS = 'TURN_OFF_WEBSOCKETS'

export const CLEAR_SEARCH_CUSTOMERS = 'CLEAR_SEARCH_CUSTOMERS'
export const CLEAR_SEARCH_ADDRESSES = 'CLEAR_SEARCH_ADDRESSES'
export const AUTOSUGGEST_CUSTOMERS = 'AUTOSUGGEST_CUSTOMERS'
export const AUTOSUGGEST_SUBCUSTOMERS = 'AUTOSUGGEST_SUBCUSTOMERS'
export const AUTOSUGGEST_ADDRESSES = 'AUTOSUGGEST_ADDRESSES'
export const AUTOSUGGEST_ORGANISATIONS = 'AUTOSUGGEST_ORGANISATIONS'
export const SAVED_SELECTED_ORDER_IDS = 'SAVED_SELECTED_ORDER_IDS'
export const SAVED_ADMIN_PREADVICE_SEARCH_PHRASE = 'SAVED_ADMIN_PREADVICE_SEARCH_PHRASE'
export const SAVED_ADMIN_SERVICE_UPSALES_SEARCH_PHRASE = 'SAVED_ADMIN_SERVICE_UPSALES_SEARCH_PHRASE'

export const HTTP_FAILURE_ERRORS_READ = 'HTTP_FAILURE_ERRORS_READ'

export const GET_ORDER_PRICE_DATA = httpActionTypes('GET_ORDER_PRICE_DATA')
export const GET_ORDER_PRICE_DATA_RESET = 'GET_ORDER_PRICE_DATA_RESET'
export const GET_MULTISTOP_ORDER_PRICE_DATA = httpActionTypes('GET_MULTISTOP_ORDER_PRICE_DATA')
export const GET_MULTILEG_ORDER_PRICE_DATA = httpActionTypes('GET_MULTILEG_ORDER_PRICE_DATA')
export const GET_INVOICED_ORDER_PRICE_DATA = httpActionTypes('GET_INVOICED_ORDER_PRICE_DATA')
export const CLEAR_PRICE_CORRECTIONS = 'CLEAR_PRICE_CORRECTIONS'
export const RESEND_ORDER_FOR_INVOICING = httpActionTypes('RESEND_ORDER_FOR_INVOICING')
export const RESEND_ORDERS_FOR_INVOICING = httpActionTypes('RESEND_ORDERS_FOR_INVOICING')
export const GET_INVOICING_DATA = httpActionTypes('GET_INVOICING_DATA')
export const CREATE_OR_UPDATE_ADDRESS = httpActionTypes('CREATE_OR_UPDATE_ADDRESS')
export const UPDATE_CUSTOMER_INFORMATION = httpActionTypes('UPDATE_CUSTOMER_INFORMATION')
export const CREATE_OR_UPDATE_RECURRING_ORDER = httpActionTypes('CREATE_OR_UPDATE_RECURRING_ORDER', true)
export const CREATE_OR_UPDATE_RECURRING_ORDERS = httpActionTypes('CREATE_OR_UPDATE_RECURRING_ORDERS', true)
export const GET_RECURRING_ORDERS = httpActionTypes('GET_RECURRING_ORDERS', true)
export const GET_RECURRING_ORDERS_FOR_DEPARTMENTS = httpActionTypes('GET_RECURRING_ORDERS_FOR_DEPARTMENTS', true)
export const GET_RECURRING_ORDER_EXECUTIONS = httpActionTypes('GET_RECURRING_ORDER_EXECUTIONS', true)
export const APPLY_SUGGESTED_ROUTE = httpActionTypes('APPLY_SUGGESTED_ROUTE', true)
export const CANCEL_SUGGESTED_ROUTE = httpActionTypes('CANCEL_SUGGESTED_ROUTE', true)
export const DELETE_UNASSIGNED_ROUTE_POINT_SUGGESTIONS = httpActionTypes(
  'DELETE_UNASSIGNED_ROUTE_POINT_SUGGESTIONS',
  true
)
export const REORDER_SUGGESTED_ROUTE = httpActionTypes('REORDER_SUGGESTED_ROUTE', true)
export const CREATE_SUGGESTED_ROUTE = httpActionTypes('CREATE_SUGGESTED_ROUTE', true)
export const GET_ORDER_CORRECTIONS = httpActionTypes('GET_ORDER_CORRECTIONS', true)
export const REJECT_ORDER_CORRECTION = httpActionTypes('REJECT_ORDER_CORRECTION', true)
export const ACCEPT_ORDER_CORRECTION = httpActionTypes('ACCEPT_ORDER_CORRECTION', true)

export const GET_NUMBER_SERIES_VALUES = httpActionTypes('GET_NUMBER_SERIES_VALUES', false)
export const EXECUTE_RECURRING_ORDERS = httpActionTypes('EXECUTE_RECURRING_ORDERS', false)
export const RESET_RECURRING_ORDER = httpActionTypes('RESET_RECURRING_ORDER', true)
export const EXECUTE_ORDER_INVOICING = httpActionTypes('EXECUTE_ORDER_INVOICING', false)
export const EXECUTE_ORDER_INVOICING_BATCH = httpActionTypes('EXECUTE_ORDER_INVOICING_BATCH', false)
export const UPDATE_ORDER_INVOICING_PAUSED = httpActionTypes('UPDATE_ORDER_INVOICING_PAUSED', true)
export const UPDATE_ORDER_ACCEPT_INCOMPLETE_PRICE = httpActionTypes('UPDATE_ORDER_ACCEPT_INCOMPLETE_PRICE', true)
export const REQUEST_PRICE_UPDATE = httpActionTypes('REQUEST_PRICE_UPDATE', true)
export const SET_ORDER_INVOICING_STATUS_MANUALLY = httpActionTypes('SET_ORDER_INVOICING_STATUS_MANUALLY', true)
export const ADD_UNIT_TO_ORDERS_FOR_INVOICING = httpActionTypes('ADD_UNIT_TO_ORDERS_FOR_INVOICING', true)
export const UPDATE_RECIPIENT_PHONE_NUMBER = httpActionTypes('UPDATE_RECIPIENT_PHONE_NUMBER', true)
export const UPDATE_RECIPIENT_EMAIL_ADDRESS = httpActionTypes('UPDATE_RECIPIENT_EMAIL_ADDRESS', true)
export const LOCK_OR_UNLOCK_PRE_ADVICE = httpActionTypes('LOCK_OR_UNLOCK_PRE_ADVICE', true)
export const FINISH_PRE_ADVICE_FOLLOWUP = httpActionTypes('FINISH_PRE_ADVICE_FOLLOWUP', true)
export const GET_ORDER_INVOICING_RESULT = httpActionTypes('GET_ORDER_INVOICING_RESULT', true)
export const GET_INVOICE_ITEMS = httpActionTypes('GET_INVOICE_ITEMS', true)
export const COUNT_ORDERS_FOR_INVOICING_EXECUTION = httpActionTypes('COUNT_ORDERS_FOR_INVOICING_EXECUTION', true)

export const SHOW_SNACKBAR = 'SHOW_SNACKBAR'
export const CREATE_SNACKBAR = 'CREATE_SNACKBAR'
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR'
export const DISMISS_ALL_SNACKBARS = 'DISMISS_SNACKBARS'

export const MOVE_ORDERS = httpActionTypes('MOVE_ORDERS', true)
export const MOVE_ORDERS_NEW = httpActionTypes('MOVE_ORDERS_NEW', true)
export const TOGGLE_SLOT_MANUAL_PLANNING = httpActionTypes('TOGGLE_SLOT_MANUAL_PLANNING', true)
export const GET_DEPARTMENTS_IN_SAME_COUNTRY = httpActionTypes('GET_DEPARTMENTS_IN_SAME_COUNTRY', true)

export const GRID_INIT = 'GRID_INIT'
export const GRID_SELECT_UPDATE = 'GRID_SELECT_UPDATE'
export const GRID_SELECT_NONE = 'GRID_SELECT_NONE'
export const GRID_SORT_BY_COLUMN = 'GRID_SORT_BY_COLUMN'
export const GRID_SORT_DESCENDING = 'GRID_SORT_DESCENDING'
export const GRID_RESET_SORT = 'GRID_RESET_SORT'
export const GRID_INIT_SORT = 'GRID_INIT_SORT'
export const GRID_DISABLE_ROWS = 'GRID_DISABLE_ROWS'

export const TOGGLE_POPOVER = 'TOGGLE_POPOVER'
export const HIDE_POPOVER = 'HIDE_POPOVER'
export const SET_COLUMN_FILTER = 'SET_COLUMN_FILTER'
export const CLEAR_COLUMN_FILTER = 'CLEAR_COLUMN_FILTER'
export const CLEAR_ALL_COLUMN_FILTERS = 'CLEAR_ALL_COLUMN_FILTERS'

export const CREATE_LABEL = httpActionTypes('CREATE_LABEL', true)
export const SEND_PRE_PICKUP_SMS = httpActionTypes('SEND_PRE_PICKUP_SMS', true)
export const SEND_PRE_PICKUP_SMS_NEW = httpActionTypes('SEND_PRE_PICKUP_SMS_NEW')
export const CREATE_MULTIPLE_LABEL = httpActionTypes('CREATE_MULTIPLE_LABEL')
export const CREATE_RETURN_LABELS = httpActionTypes('CREATE_RETURN_LABELS')
export const CREATE_LABELS_FOR_DEPARTMENT = httpActionTypes('CREATE_LABELS_FOR_DEPARTMENT')
export const GET_MULTIPLE_LABEL = httpActionTypes('GET_MULTIPLE_LABEL', true)
export const CREATE_AWB_LABEL = httpActionTypes('CREATE_AWB_LABEL')
export const GET_AIR_EXPRESS_RECURRING_SCHEDULES = httpActionTypes('GET_AIR_EXPRESS_RECURRING_SCHEDULES', true)
export const GET_AIR_EXPRESS_RECURRING_TEMPLATES_FOR_SCHEDULE = httpActionTypes(
  'GET_AIR_EXPRESS_RECURRING_TEMPLATES_FOR_SCHEDULE',
  true
)
export const GET_AIR_EXPRESS_RECURRING_TEMPLATES_FOR_DATE = httpActionTypes(
  'GET_AIR_EXPRESS_RECURRING_TEMPLATES_FOR_DATE'
)
export const CREATE_AIR_EXPRESS_RECURRING_TEMPLATE = httpActionTypes('CREATE_AIR_EXPRESS_RECURRING_TEMPLATE')
export const CREATE_AIR_EXPRESS_RECURRING_SCHEDULE = httpActionTypes('CREATE_AIR_EXPRESS_RECURRING_SCHEDULE')
export const EDIT_RECURRING_SCHEDULE = httpActionTypes('EDIT_RECURRING_SCHEDULE')
export const DELETE_RECURRING_SCHEDULE = httpActionTypes('DELETE_RECURRING_SCHEDULE')
export const ADD_TEMPLATES_TO_SCHEDULE = httpActionTypes('ADD_TEMPLATES_TO_SCHEDULE')
export const DELETE_RECURRING_TEMPLATE_FROM_SCHEDULE = httpActionTypes('DELETE_RECURRING_TEMPLATE_FROM_SCHEDULE')
export const GROUP_ORDERS = httpActionTypes('GROUP_ORDERS', true)
export const UNGROUP_ORDERS = httpActionTypes('UNGROUP_ORDERS', true)
export const SAVE_CUSTOMER_EVENT_EMAIL = httpActionTypes('SAVE_CUSTOMER_EVENT_EMAIL', true)
export const GET_CUSTOMER_EVENT_EMAIL = httpActionTypes('GET_CUSTOMER_EVENT_EMAIL', true)
export const GET_ORDER_DEVIATIONS = httpActionTypes('GET_ORDER_DEVIATIONS', true)
export const CREATE_ORDER_DEVIATION = httpActionTypes('CREATE_ORDER_DEVIATION', true)
export const UPDATE_ORDER_DEVIATION = httpActionTypes('UPDATE_ORDER_DEVIATION', true)
export const DELETE_ORDER_DEVIATION = httpActionTypes('DELETE_ORDER_DEVIATION', true)
export const GET_CUSTOMER_SMS_TEXT = httpActionTypes('GET_CUSTOMER_SMS_TEXT', true)
export const CREATE_UPDATE_CUSTOMER_SMS_TEXT = httpActionTypes('CREATE_UPDATE_CUSTOMER_SMS_TEXT', true)
export const SEARCH_DELETED_ORDERS = httpActionTypes('SEARCH_DELETED_ORDERS', true)
export const DELETED_ORDERS_EXTENDED_SEARCH = httpActionTypes('DELETED_ORDERS_EXTENDED_SEARCH', true)
export const ADD_RECURRING_ORDER_EXCEPTIONS = httpActionTypes('ADD_RECURRING_ORDER_EXCEPTIONS', true)
export const DELETE_RECURRING_ORDER = httpActionTypes('DELETE_RECURRING_ORDER', true)
export const DELETE_RECURRING_ORDER_EXCEPTIONS = httpActionTypes('DELETE_RECURRING_ORDER_EXCEPTIONS', true)
export const CLEAR_SEARCH_ADDRESSES_FOR_ADDRESSES_PAGE = 'CLEAR_SEARCH_ADDRESSES_FOR_ADDRESSES_PAGE'
export const GET_RECURRING_EXECUTED_DATES = httpActionTypes('GET_RECURRING_EXECUTED_DATES', false)

export const SET_CUSTOMER_SERVICE_LEVEL = httpActionTypes('SET_CUSTOMER_SERVICE_LEVEL', true)

export const ASSIGN_UNIT_TO_SLOT = httpActionTypes('ASSIGN_UNIT_TO_SLOT', true)
export const UNASSIGN_UNIT_FROM_SLOT = httpActionTypes('UNASSIGN_UNIT_FROM_SLOT', true)

export const ASSIGN_VEHICLE_TO_SLOT = httpActionTypes('ASSIGN_VEHICLE_TO_SLOT', true)
export const UNASSIGN_VEHICLE_FROM_SLOT = httpActionTypes('UNASSIGN_VEHICLE_FROM_SLOT', true)

export const ASSIGN_COURIER_TO_SLOT = httpActionTypes('ASSIGN_COURIER_TO_SLOT', true)
export const UNASSIGN_COURIER_FROM_SLOT = httpActionTypes('UNASSIGN_COURIER_FROM_SLOT', true)

export const GET_APP_SETTING = httpActionTypes('GET_APP_SETTING')
export const UPDATE_APP_SETTING = httpActionTypes('UPDATE_APP_SETTING')

export const WEB_SOCKET_SET_TYPES = 'WEB_SOCKET_SET_TYPES'
export const WEB_SOCKET_SET_FILTERS = 'WEB_SOCKET_SET_FILTERS'
export const WEB_SOCKET_ADD_CONTEXTS = 'WEB_SOCKET_ADD_CONTEXTS'
export const WEB_SOCKET_REMOVE_CONTEXTS = 'WEB_SOCKET_REMOVE_CONTEXTS'
export const WEB_SOCKET_SET_CONTEXT = 'WEB_SOCKET_SET_CONTEXT'
export const WEB_SOCKET_SET_CONTEXT_DATES = 'WEB_SOCKET_SET_CONTEXT_DATES'
export const WEB_SOCKET_SET_CONTEXT_CONSIGNMENT_STATES = 'WEB_SOCKET_SET_CONTEXT_CONSIGNMENT_STATES'
export const WEB_SOCKET_SET_CONTEXT_CUSTOMERS = 'WEB_SOCKET_SET_CONTEXT_CUSTOMERS'
export const WEB_SOCKET_SET_CONTEXT_DEPARTMENTS = 'WEB_SOCKET_SET_CONTEXT_DEPARTMENTS'

export const GET_COURIERS_FOR_UNIT = httpActionTypes('GET_COURIERS_FOR_UNIT', true)
export const GET_COURIER_USERS_FOR_DEPARTMENT = httpActionTypes('GET_COURIER_USERS_FOR_DEPARTMENT', true)
export const GET_COURIER_USER_BY_ID = httpActionTypes('GET_COURIER_USER_BY_ID', true)
export const SEARCH_COURIER_USERS = httpActionTypes('SEARCH_COURIER_USERS', false)
export const SEARCH_UNITS = httpActionTypes('SEARCH_UNITS', false)
export const CLEAR_SEARCH_COURIER_USERS = 'CLEAR_SEARCH_COURIER_USERS'
export const CLEAR_SEARCH_UNITS = 'CLEAR_SEARCH_VEHICLES'
export const REMOVE_FROM_UNIT_SEARCH = 'REMOVE_FROM_VEHICLE_SEARCH'
export const ASSIGN_COURIER_TO_UNIT = httpActionTypes('ASSIGN_COURIER_TO_UNIT', true)
export const ASSIGN_COURIER_TO_DEPARTMENT = httpActionTypes('ASSIGN_COURIER_TO_DEPARTMENT', true)
export const ASSIGN_UNIT_TO_DEPARTMENT = httpActionTypes('ASSIGN_UNIT_TO_DEPARTMENT', true)
export const SET_UNIT_DEFAULT_VEHICLE = httpActionTypes('SET_UNIT_DEFAULT_VEHICLE', true)
export const UNASSIGN_UNIT_FROM_DEPARTMENT = httpActionTypes('UNASSIGN_UNIT_FROM_DEPARTMENT', true)
export const UNASSIGN_COURIER_FROM_UNIT = httpActionTypes('UNASSIGN_COURIER_FROM_UNIT', true)
export const CREATE_COURIER_USER = httpActionTypes('CREATE_COURIER_USER', true)
export const UPDATE_COURIER_USER = httpActionTypes('UPDATE_COURIER_USER', true)

export const UPDATE_ORDER_VEHICLE_TYPE = httpActionTypes('UPDATE_ORDER_VEHICLE_TYPE', true)

export const GET_LATEST_OPTIMIZING_ID_BY_SLOT = httpActionTypes('GET_LATEST_OPTIMIZING_ID_BY_SLOT')

export const GRID_SAVE_IDS = 'GRID_SAVE_IDS'
export const GRID_CLEAR_SAVED = 'GRID_CLEAR_SAVED'

export const GET_DEPARTMENT_GROUPS = httpActionTypes('GET_DEPARTMENT_GROUPS', true)
export const GET_CUSTOMER_GROUPS = httpActionTypes('GET_CUSTOMER_GROUPS', true)
export const GET_CUSTOMER_GROUP_MEMBERS = httpActionTypes('GET_CUSTOMER_GROUP_MEMBERS', false)
export const CREATE_CUSTOMER_GROUP = httpActionTypes('CREATE_CUSTOMER_GROUP', true)
export const UPDATE_CUSTOMER_GROUP = httpActionTypes('UPDATE_CUSTOMER_GROUP', true)
export const DELETE_CUSTOMER_GROUP = httpActionTypes('DELETE_CUSTOMER_GROUP', true)
export const CREATE_DEPARTMENT_GROUP = httpActionTypes('CREATE_DEPARTMENT_GROUP', true)
export const UPDATE_DEPARTMENT_GROUP = httpActionTypes('UPDATE_DEPARTMENT_GROUP', true)
export const DELETE_DEPARTMENT_GROUP = httpActionTypes('DELETE_DEPARTMENT_GROUP', true)

export const GET_AIRLINES = httpActionTypes('GET_AIRLINES', true)
export const GET_AIRPORTS = httpActionTypes('GET_AIRPORTS', true)
export const GET_AIREXPRESS_RECURRING_TEMPLATES = httpActionTypes('GET_RECURRING_TEMPLATES', true)
export const GET_AIRLINE_TERMINALS = httpActionTypes('GET_AIRLINE_TERMINALS', true)
export const CREATE_OR_UPDATE_AIRLINE_TERMINAL = httpActionTypes('CREATE_OR_UPDATE_AIRLINE_TERMINAL', true)
export const CREATE_AIRPORT = httpActionTypes('CREATE_AIRPORT', true)
export const UPDATE_AIRPORT = httpActionTypes('UPDATE_AIRPORT', true)
export const DELETE_AIRPORT = httpActionTypes('DELETE_AIRPORT', true)
export const CREATE_AIRLINE = httpActionTypes('CREATE_AIRLINE', true)
export const UPDATE_AIRLINE = httpActionTypes('UPDATE_AIRLINE', true)
export const DELETE_AIRLINE = httpActionTypes('DELETE_AIRLINE', true)
export const GET_MASTER_AIR_WAYBILL_BY_MANIFEST = httpActionTypes('GET_MASTER_AIR_WAYBILL_BY_MANIFEST', true)
export const GET_AIRWAYBILL_STOCK_REMAINDERS = httpActionTypes('EXTERNAL_AWB_SERIES_REMAINDER', true)
export const SEARCH_MANIFESTS = httpActionTypes('SEARCH_MANIFESTS', true)
export const SEND_MANIFEST_EMAILS = httpActionTypes('SEND_MANIFEST_EMAIL', true)
export const GET_FLIGHT_SCHEDULE_UPLOADS = httpActionTypes('GET_FLIGHT_SCHEDULE_UPLOADS', false)
export const UPLOAD_FLIGHT_SCHEDULES = httpActionTypes('UPLOAD_FLIGHT_SCHEDULES', false)
export const GET_LOCALLY_ADDED_FLIGHT_SCHEDULES = httpActionTypes('GET_LOCALLY_ADDED_FLIGHT_SCHEDULES', true)
export const ADD_LOCAL_FLIGHT_SCHEDULE = httpActionTypes('ADD_LOCAL_FLIGHT_SCHEDULE', true)
export const UPDATE_LOCAL_FLIGHT_SCHEDULE = httpActionTypes('UPDATE_LOCAL_FLIGHT_SCHEDULE', true)
export const DELETE_LOCAL_FLIGHT_SCHEDULE = httpActionTypes('DELETE_LOCAL_FLIGHT_SCHEDULE', true)
export const SEARCH_SCHEDULED_FLIGHTS = httpActionTypes('SEARCH_SCHEDULED_FLIGHTS', false)
export const SEARCH_LIVE_FLIGHTS = httpActionTypes('SEARCH_LIVE_FLIGHTS', false)
export const SEARCH_AIR_EXPRESS_ORDERS = httpActionTypes('SEARCH_AIR_EXPRESS_ORDERS', true)
export const SEARCH_AIR_EXPRESS_DUTIABLE_ORDERS = httpActionTypes('SEARCH_AIR_EXPRESS_DUTIABLE_ORDERS', true)
export const GET_AIR_EXPRESS_ORDER = httpActionTypes('GET_AIR_EXPRESS_ORDER', true)
export const GET_AIR_EXPRESS_ORDER_WITH_ALL_LEGS = httpActionTypes('GET_AIR_EXPRESS_ORDER_WITH_ALL_LEGS', true)
export const DELETE_AIREXPRESS_ORDERS = httpActionTypes('DELETE_AIREXPRESS_ORDERS', true)
export const FIND_MASTER_AWBS = httpActionTypes('FIND_MASTER_AWBS', false)
export const CREATE_AIR_EXPRESS_CONSIGNMENT_EVENT_FOR_ORDER = httpActionTypes(
  'CREATE_AIR_EXPRESS_CONSIGNMENT_EVENT_FOR_ORDER',
  true
)
export const CREATE_AIR_EXPRESS_CONSIGNMENT_EVENT = httpActionTypes('CREATE_AIR_EXPRESS_CONSIGNMENT_EVENT', true)
export const GET_INVOICING_EVENT_DETAILS = httpActionTypes('GET_INVOICING_EVENT_DETAILS', false)
export const INVOICE_AIR_EXPRESS_ORDER = httpActionTypes('INVOICE_AIR_EXPRESS_ORDER', false)

export const EVENTS_WITH_SENT_LM_STATUS = httpActionTypes('EVENTS_WITH_SENT_LM_STATUS')

export const RESET_ORDER_PRICE_INFO = 'RESET_ORDER_PRICE_INFO'
export const SEARCH_H2_ORDER = httpActionTypes('SEARCH_H2_ORDER')
export const SEND_MANUAL_DELAY_SMS = httpActionTypes('SEND_MANUAL_DELAY_SMS')
export const SEND_MANUAL_DELAY_SMS_NEW = httpActionTypes('SEND_MANUAL_DELAY_SMS_NEW')
export const UPDATE_ORDER_SERVICE_LEVEL = httpActionTypes('UPDATE_ORDER_SERVICE_LEVEL', true)
export const GET_IMPORT_RESULTS = httpActionTypes('GET_IMPORT_RESULTS', true)
export const GET_IMPORT_STATUS = httpActionTypes('GET_IMPORT_STATUS', true)
export const CLEAR_ENTITIES = 'CLEAR_ENTITIES'
export const CLEAR_ALL_ENTITIES = 'CLEAR_ALL_ENTITIES'
export const CLEAR_ENTITY_COMMAND_RESULT = 'CLEAR_ENTITY_COMMAND_RESULT'
export const SET_PICKUP_DELIVERY_DURATION = httpActionTypes('SET_PICKUP_DELIVERY_DURATION')
export const GET_ORDERS_BY_ORDER_IDS = httpActionTypes('GET_ORDERS_BY_ORDER_IDS', true)
export const UPDATE_CUSTOMER_SENDER_NAME = httpActionTypes('UPDATE_CUSTOMER_SENDER_NAME', true)
export const UPDATE_CUSTOMER_HD_INVOICING_FROM_DATE = httpActionTypes('UPDATE_CUSTOMER_HD_INVOICING_FROM_DATE', true)
export const UPDATE_CUSTOMER_NPS_FROM_DATE = httpActionTypes('UPDATE_CUSTOMER_NPS_FROM_DATE', true)
export const UPDATE_CUSTOMER = httpActionTypes('UPDATE_CUSTOMER', true)
export const CREATE_CUSTOMER = httpActionTypes('CREATE_CUSTOMER', true)
export const UPDATE_CUSTOMER_BILLING_NUMBER = httpActionTypes('UPDATE_CUSTOMER_BILLING_NUMBER', true)
export const UPDATE_CUSTOMER_BOOKING_PAGE_ACCESS = httpActionTypes('UPDATE_CUSTOMER_BOOKING_PAGE_ACCESS', true)
export const UPDATE_BRANDED_TRACKING_ACCESS = httpActionTypes('UPDATE_BRANDED_TRACKING_ACCESS', true)
export const UPDATE_CUSTOMER_RETURN_LAST_LEG = httpActionTypes('UPDATE_CUSTOMER_RETURN_LAST_LEG', true)
export const UPDATE_CUSTOMER_USE_TERMINAL_ADDRESS = httpActionTypes('UPDATE_CUSTOMER_USE_TERMINAL_ADDRESS', true)
export const UPDATE_CUSTOMER_RETURN_ADDRESS = httpActionTypes('UPDATE_CUSTOMER_RETURN_ADDRESS', true)
export const UPDATE_CUSTOMER_INVOICE_SPLIT = httpActionTypes('UPDATE_CUSTOMER_INVOICE_SPLIT', true)
export const UPDATE_CUSTOMER_SCAN_TO_CREATE_PACKAGES = httpActionTypes('UPDATE_CUSTOMER_SCAN_TO_CREATE_PACKAGES', true)

export const CREATE_ROLE = httpActionTypes('CREATE_ROLE', true)
export const UPDATE_ROLE = httpActionTypes('UPDATE_ROLE', true)
export const DELETE_ROLE = httpActionTypes('DELETE_ROLE', true)
export const ROLES = httpActionTypes('ROLES', true)

export const CREATE_BANNER = httpActionTypes('CREATE_BANNER', true)
export const UPDATE_BANNER = httpActionTypes('UPDATE_BANNER', true)
export const DELETE_BANNER = httpActionTypes('DELETE_BANNER', true)
export const GET_ALL_BANNERS = httpActionTypes('GET_ALL_BANNERS', true)
export const GET_PUBLISHED_BANNERS = httpActionTypes('GET_PUBLISHED_BANNERS', true)
export const GET_BANNER_BY_ID = httpActionTypes('GET_BANNER_BY_ID', true)
export const GET_LOGIN_PAGE_BANNER = httpActionTypes('GET_LOGIN_PAGE_BANNER', false)
export const VALIDATE_BANNER_URL = httpActionTypes('VALIDATE_BANNER_URL', false)

export const CREATE_WHATS_THIS_MODAL = httpActionTypes('CREATE_WHATS_THIS_MODAL', true)
export const UPDATE_WHATS_THIS_MODAL = httpActionTypes('UPDATE_WHATS_THIS_MODAL', true)
export const DELETE_WHATS_THIS_MODAL = httpActionTypes('DELETE_WHATS_THIS_MODAL', true)
export const GET_ALL_WHATS_THIS_MODALS = httpActionTypes('GET_ALL_WHATS_THIS_MODALS', true)
export const GET_WHATS_THIS_MODAL_BY_ID = httpActionTypes('GET_WHATS_THIS_MODAL_BY_ID', true)
export const GET_PUBLISHED_WHATS_THIS_MODALS = httpActionTypes('GET_PUBLISHED_WHATS_THIS_MODALS', true)

export const FIND_AVAILABLE_DELIVERY_TIMES = httpActionTypes('FIND_AVAILABLE_DELIVERY_TIMES', false)
export const UPDATE_DELIVERY_TIME = httpActionTypes('UPDATE_DELIVERY_TIME', true)
export const REMOVE_BOOKING_TIMES = httpActionTypes('REMOVE_BOOKING_TIMES', true)
export const QUICK_EDIT_SHIPMENT = httpActionTypes('QUICK_EDIT_SHIPMENT', true)

export const SEARCH_ORGANISATIONS_TWO = httpActionTypes('SEARCH_ORGANISATIONS_TWO', false)
export const GET_PRE_ADVICE_POLICIES = httpActionTypes('GET_PRE_ADVICE_POLICIES', true)
export const GET_PRE_ADVICE = httpActionWithAccessTypes(
  'GET_PRE_ADVICE',
  'preAdviceForOrder',
  ['PLANNER_PRE_ADVICE', 'PLANNER_SHIPMENTS'],
  plannerTypesWithWriteAccess.concat(ROLE_ADMIN),
  true
)

export const GET_PRE_ADVICE_SERVICE_POLICIES = httpActionTypes('GET_PRE_ADVICE_SERVICE_POLICIES', true)
export const CREATE_PRE_ADVICE_POLICY = httpActionTypes('CREATE_PRE_ADVICE_POLICY', false)
export const DELETE_PRE_ADVICE_POLICY = httpActionTypes('DELETE_PRE_ADVICE_POLICY', false)

export const PURCHASE_ORDER_TWO = httpActionTypes('PURCHASE_ORDER_TWO', false)
export const SEARCH_SHIPMENT_ID = httpActionTypes('SEARCH_SHIPMENT_ID', true)
export const VALIDATE_POSTAL_CODE = httpActionTypes('VALIDATE_POSTAL_CODE', false)

export const EXTERNAL_TRACKING_RESULT = httpActionTypes('EXTERNAL_TRACKING_RESULT', false)

export const DISABLE_JOB = httpActionTypes('DISABLE_JOB', false)
export const ENABLE_JOB = httpActionTypes('ENABLE_JOB', false)
export const GET_SUGGESTED_UNITS_FOR_LOCATION = httpActionTypes('GET_SUGGESTED_UNITS_FOR_LOCATION', false)
export const GET_SUGGESTED_UNITS_ON_PICKUP = httpActionTypes('GET_SUGGESTED_UNITS_ON_PICKUP', false)
export const GET_SUGGESTED_UNITS_ON_DELIVERY = httpActionTypes('GET_SUGGESTED_UNITS_ON_DELIVERY', false)
export const CREATE_BILLING_ORDER = httpActionTypes('CREATE_BILLING_ORDER', true)

export const AVAILABLE_DELIVERY_TIMES_ADD = 'AVAILABLE_DELIVERY_TIMES_ADD'

export const GET_ARTICLES = httpActionTypes('GET_ARTICLES')
export const GET_ARTICLES_IN_GROUP = httpActionTypes('GET_ARTICLES_IN_GROUP')
export const GET_VENDOR = httpActionTypes('GET_VENDOR', true)
export const CONNECT_UNITS_TO_VENDOR_SITE = httpActionTypes('CONNECT_UNITS_TO_VENDOR_SITE', true)
export const CREATE_MULTILEG_RETURN = httpActionTypes('CREATE_MULTILEG_RETURN')
export const CREATE_MULTILEG_RETURNS = httpActionTypes('CREATE_MULTILEG_RETURNS')
export const MANUALLY_MARK_RETURNS_AS_HANDLED = httpActionTypes('MANUALLY_MARK_RETURNS_AS_HANDLED')
export const UPDATE_MULTISTOP_PRICE_CORRECTION = httpActionTypes('UPDATE_MULTISTOP_PRICE_CORRECTION', true)
export const UPDATE_MULTILEG_PRICE_CORRECTION = httpActionTypes('UPDATE_MULTILEG_PRICE_CORRECTION', true)
export const SEARCH_RECIPIENT_ID = httpActionTypes('SEARCH_RECIPIENT_ID')
export const ADD_NEW_PRODUCTION_TIME_WINDOW = httpActionTypes('ADD_NEW_PRODUCTION_TIME_WINDOW')
