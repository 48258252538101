import { List, Map } from 'immutable'
import {
  ROUTER_LOCATION_CHANGE_ACTION,
  SAVED_ADMIN_PREADVICE_SEARCH_PHRASE,
  SAVED_ADMIN_SERVICE_UPSALES_SEARCH_PHRASE
} from '../actions/actionTypes'

const initialState = Map({
  adminPreadviceSearchPhrase: '',
  adminPreadviceFilterOption: '',
  adminServiceUpsalesSearchPhrase: '',
  adminServiceUpsalesFilterOption: ''
})

interface SaveSelectedOrderIdsAction {
  type: typeof SAVED_ADMIN_PREADVICE_SEARCH_PHRASE | typeof SAVED_ADMIN_SERVICE_UPSALES_SEARCH_PHRASE
  searchPhrase: string
  filterOption: string
}

interface LocationChangeAction {
  type: typeof ROUTER_LOCATION_CHANGE_ACTION
  body: Map<number, List<unknown>>
}

type InvoicingDataAction = LocationChangeAction | SaveSelectedOrderIdsAction

export default function reducer(state = initialState, action: InvoicingDataAction) {
  switch (action.type) {
    case ROUTER_LOCATION_CHANGE_ACTION:
      if (
        document.location.href.includes('admin/preadvice') ||
        document.location.href.includes('admin/services-and-texts/upsales-rules')
      ) {
        return state
      }
      return initialState
    case SAVED_ADMIN_PREADVICE_SEARCH_PHRASE:
      return state
        .set('adminPreadviceSearchPhrase', action.searchPhrase)
        .set('adminPreadviceFilterOption', action.filterOption)
    case SAVED_ADMIN_SERVICE_UPSALES_SEARCH_PHRASE:
      return state
        .set('adminServiceUpsalesSearchPhrase', action.searchPhrase)
        .set('adminServiceUpsalesFilterOption', action.filterOption)
    default:
      return state
  }
}
