export enum CountryCode {
  AD = 'AD',
  AT = 'AT',
  BE = 'BE',
  BA = 'BA',
  BG = 'BG',
  HR = 'HR',
  CZ = 'CZ',
  DK = 'DK',
  GB = 'GB',
  EE = 'EE',
  FO = 'FO',
  FI = 'FI',
  FR = 'FR',
  DE = 'DE',
  GI = 'GI',
  GR = 'GR',
  HU = 'HU',
  IS = 'IS',
  IE = 'IE',
  IT = 'IT',
  LV = 'LV',
  LT = 'LT',
  LU = 'LU',
  MK = 'MK',
  MT = 'MT',
  MC = 'MC',
  ME = 'ME',
  NL = 'NL',
  NO = 'NO',
  PL = 'PL',
  PT = 'PT',
  RO = 'RO',
  RU = 'RU',
  SM = 'SM',
  RS = 'RS',
  SK = 'SK',
  SI = 'SI',
  ES = 'ES',
  SE = 'SE',
  CH = 'CH',
  UA = 'UA'
}
