import { ConsignmentState } from '@glow/entity-types'
import { isImmutable } from 'immutable'
import { DateTime } from 'luxon'
import { CustomerIdType } from '../domain/customer'
import { MainAccessType } from '../domain/mainAccessType'
import { DepartmentIdType } from '../types/coreEntitiesTypes'
import { ImmutableMap } from '../types/immutableTypes'

export function formatSocketQueryParams(webSocketContext: WebSocketContextImmutable): string {
  const params = new URLSearchParams()

  if (!webSocketContext || !isImmutable(webSocketContext)) {
    return `?dates=${DateTime.local().toISODate()}`
  }

  webSocketContext.get('dates')?.forEach((d) => {
    params.append('dates', d)
  })

  webSocketContext.get('departmentIds')?.forEach((id) => {
    params.append('departmentIds', String(id))
  })

  webSocketContext.get('customerIds')?.forEach((id) => {
    params.append('customerIds', String(id))
  })

  webSocketContext.get('contexts')?.forEach((ctx) => {
    params.append('contexts', ctx)
  })

  webSocketContext.get('types')?.forEach((ctx) => {
    params.append('types', ctx)
  })

  webSocketContext.get('filters')?.forEach((ctx) => {
    params.append('filters', ctx)
  })

  webSocketContext.get('consignmentStates')?.forEach((ctx) => {
    params.append('consignmentStates', ctx)
  })

  const str = params.toString()
  return str ? '?' + str : ''
}

export interface WebSocketContextImmutable extends ImmutableMap<WebSocketContext> {}

export interface WebSocketContext {
  dates: string[]
  departmentIds: DepartmentIdType[]
  customerIds: CustomerIdType[]
  contexts: WebSocketContextTypes[]
  types: MainAccessType[]
  filters: WebSocketFilterTypes[]
  consignmentStates: ConsignmentState[]
}

export type WebSocketContextTypes =
  | 'ADMIN'
  | 'AIR_EXPRESS'
  | 'COURIER'
  | 'CUSTOMER'
  | 'DISPATCHING'
  | 'DISPATCHING_MODAL'
  | 'EXPORTING'
  | 'IMPORTING'
  | 'INVOICING'
  | 'LABEL'
  | 'LIVE'
  | 'MANAGE_RETURNS'
  | 'NONE'
  | 'PLANNER'
  | 'PREADVICE_FOLLOWUP'
  | 'RECURRING'

export type WebSocketFilterTypes = 'CONSIGNMENT_RESOLVED'
