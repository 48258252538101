import { Map } from 'immutable'
import { ADD_NOTIFICATION, DISMISS_ALL_NOTIFICATIONS, DISMISS_NOTIFICATION } from '../actions/actionTypes'
import { Notification } from '../types/notificationTypes'

interface AddNotificationAction {
  type: typeof ADD_NOTIFICATION
  id: string
  props: Notification
}

interface DismissNotificationAction {
  type: typeof DISMISS_NOTIFICATION
  id: string
}

interface DismissAllNotificationsAction {
  type: typeof DISMISS_ALL_NOTIFICATIONS
}

const initialState = Map()

type NotificationAction = AddNotificationAction | DismissNotificationAction | DismissAllNotificationsAction

export default function reducer(state = initialState, action: NotificationAction) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      const currentNotification = state.get(action.id) as Notification | undefined
      return state.set(
        action.id,
        action.props
          .set('id', action.id)
          ?.set('open', shouldNotificationKeepOpenedState(currentNotification, action.props))
      )
    case DISMISS_NOTIFICATION:
      const doesNotificationExist = state.has(action.id)

      return doesNotificationExist
        ? state.set(action.id, (state.get(action.id) as Notification)?.set('open', false))
        : state
    case DISMISS_ALL_NOTIFICATIONS:
      return state.map((notification) => (notification as Notification)?.set('open', false))
    default:
      return state
  }
}

function shouldNotificationKeepOpenedState(
  currentNotification: Notification | undefined,
  props: Notification
): boolean {
  if (!currentNotification) return true
  if (
    currentNotification.get('type') === props.get('type') &&
    currentNotification.get('title') === props.get('title') &&
    currentNotification.get('message') === props.get('message') &&
    !Boolean(props.get('timeout'))
  ) {
    return currentNotification.get('open') ?? true
  }
  return true
}
